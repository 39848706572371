import store from '@store'
import views from '@declarations/views'
import endpoints from '@declarations/endpoints'
import statusCode from '@declarations/statusCode'
import actions from '@declarations/actions'
import subjects from '@declarations/subjects'

const OrganizationsList = () =>
  import(/* webpackChunkName: "organizations-list" */ '@views/organizations/OrganizationsList.vue')
const OrganizationsTabs = () =>
  import(/* webpackChunkName: "organizations-tabs" */ '@views/organizations/OrganizationsTabs.vue')
const OrganizationsManager = () =>
  import(/* webpackChunkName: "organizations-manager" */ '@views/organizations/OrganizationsManager.vue')
const OrganizationsAccounts = () =>
  import(/* webpackChunkName: "organizations-accounts" */ '@views/organizations/OrganizationsAccounts.vue')
const OrganizationsWebsites = () =>
  import(/* webpackChunkName: "organizations-websites" */ '@views/organizations/OrganizationsWebsites.vue')

const appBreadcrumb = [
  {
    text: 'organizations',
    to: { name: views.ORGANIZATIONS }
  },
  {
    text: () => store.getters['organizations/editedItemName'],
    active: true
  }
]

export default [
  {
    path: `/${views.ORGANIZATIONS}`,
    name: views.ORGANIZATIONS,
    component: OrganizationsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'organizations',
          active: true
        }
      ],
      action: actions.LIST,
      subject: subjects.ORGANIZATION
    }
  },
  {
    path: `/${views.ORGANIZATIONS}/create`,
    name: views.ORGANIZATIONS_CREATE,
    component: OrganizationsManager,
    props: {
      action: 'create'
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'organizations',
          to: { name: views.ORGANIZATIONS }
        },
        {
          text: 'create',
          active: true
        }
      ],
      action: actions.CREATE,
      subject: subjects.ORGANIZATION
    }
  },
  {
    path: `/${views.ORGANIZATIONS}/edit/:id(\\d+)`,
    redirect: { name: views.ORGANIZATIONS_EDIT_GENERAL },
    name: views.ORGANIZATIONS_EDIT,
    component: OrganizationsTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('organizations/fetchItem', `${endpoints.ORGANIZATIONS}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.ORGANIZATIONS_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.ORGANIZATIONS_EDIT_GENERAL,
        component: OrganizationsManager,
        meta: {
          appBreadcrumb,
          action: actions.GET,
          subject: subjects.ORGANIZATION
        }
      },
      {
        path: 'accounts',
        name: views.ORGANIZATIONS_EDIT_ACCOUNTS,
        component: OrganizationsAccounts,
        props: route => ({
          id: route.params.id
        }),
        meta: {
          appBreadcrumb,
          action: actions.LIST,
          subject: subjects.USER
        }
      },
      {
        path: 'websites',
        name: views.ORGANIZATIONS_EDIT_WEBSITES,
        component: OrganizationsWebsites,
        props: route => ({
          id: route.params.id
        }),
        meta: {
          appBreadcrumb,
          action: actions.LIST,
          subject: subjects.WEBSITE
        }
      }
    ]
  }
]

export default Object.freeze({
  ACCOUNTS: 'accounts',
  ACCOUNTS_EDIT: 'accounts-edit',
  ACCOUNTS_EDIT_GENERAL: 'accounts-edit-general',
  ACCOUNTS_EDIT_ORGANIZATIONS: 'accounts-edit-organizations',
  ADS_TXT: 'ads-txt',
  ADS_TXT_CREATE: 'ads-txt-create',
  ADS_TXT_EDIT: 'ads-txt-edit',
  ADS_TXT_EDIT_GENERAL: 'ads-txt-edit-general',
  BIDDERS: 'bidders',
  BIDDERS_CREATE: 'bidders-create',
  BIDDERS_EDIT: 'bidders-edit',
  BIDDERS_EDIT_GENERAL: 'bidders-edit-general',
  DASHBOARD: 'dashboard',
  DASHBOARD_AUDIENCE: 'dashboard-audience',
  DASHBOARD_AUDIENCE_ADVANCED_VIEW: 'dashboard-audience-advanced-view',
  DASHBOARD_BRAND_SAFETY: 'dashboard-brand-safety',
  DASHBOARD_BROWSER: 'dashboard-browser',
  DASHBOARD_CONTENT: 'dashboard-content',
  DASHBOARD_CORE_WEB_VITALS: 'dashboard-core-web-vitals',
  DASHBOARD_CORE_WEB_VITALS_ADVANCED_VIEW: 'dashboard-core-web-vitals-advanced-view',
  DASHBOARD_COUNTRY: 'dashboard-country',
  DASHBOARD_CRAWLER: 'dashboard-crawler',
  DASHBOARD_CUSTOM_VAR: 'dashboard-custom-var',
  DASHBOARD_DEVICE: 'dashboard-device',
  DASHBOARD_DISCOVERY: 'dashboard-discovery',
  DASHBOARD_DOMAIN: 'dashboard-domain',
  DASHBOARD_GDPR: 'dashboard-gdpr',
  DASHBOARD_GDPR_ADVANCED_VIEW: 'dashboard-gdpr-advanced-view',
  DASHBOARD_MONETIZATION: 'dashboard-monetization',
  DASHBOARD_MONETIZATION_ADVANCED_VIEW: 'dashboard-monetization-advanced-view',
  DASHBOARD_OVERVIEW: 'dashboard-overview',
  DASHBOARD_PAGE_TYPE: 'dashboard-page-type',
  DASHBOARD_SOURCE_SUPPORT: 'dashboard-source-support',
  DASHBOARD_UTM: 'dashboard-utm',
  ERROR_404: 'error-404',
  FORGOT_PASSWORD: 'forgot-password',
  HOMEPAGE: 'homepage',
  LOGIN: 'login',
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_CREATE: 'organizations-create',
  ORGANIZATIONS_EDIT: 'organizations-edit',
  ORGANIZATIONS_EDIT_ACCOUNTS: 'organizations-edit-accounts',
  ORGANIZATIONS_EDIT_GENERAL: 'organizations-edit-general',
  ORGANIZATIONS_EDIT_WEBSITES: 'organizations-edit-websites',
  PROFILE: 'profile',
  PROFILE_API_TOKENS: 'profile-api-tokens',
  PROFILE_EMAIL: 'profile-email',
  PROFILE_GENERAL: 'profile-general',
  PROFILE_PASSWORD: 'profile-password',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  RESOURCE_NOT_FOUND: 'resource-not-found',
  VALIDATE: 'validate',
  WEBSITE_CONFIGURATION_ADS_TXT: 'website-configuration-ads-txt',
  WEBSITE_CONFIGURATION_BIDDERS: 'website-configuration-bidders',
  WEBSITE_CONFIGURATION_CONSUMPTION: 'website-configuration-consumption',
  WEBSITE_CONFIGURATION_CSS: 'website-configuration-css',
  WEBSITE_CONFIGURATION_EDIT: 'website-configuration-edit',
  WEBSITE_CONFIGURATION_IN_IMAGE: 'website-configuration-in-image',
  WEBSITE_CONFIGURATION_PRESIZED_DIVS: 'website-configuration-presized-divs',
  WEBSITE_CONFIGURATION_PURGE: 'website-configuration-purge',
  WEBSITE_CONFIGURATION_SCRIPTS: 'website-configuration-scripts',
  WEBSITE_CONFIGURATION_SLOTS: 'website-configuration-slots',
  WEBSITES: 'websites',
  WEBSITES_BIDDERS: 'websites-bidders',
  WEBSITES_CREATE: 'websites-create',
  WEBSITES_EDIT: 'websites-edit',
  WEBSITES_EDIT_ADS_TXT: 'websites-edit-ads-txt',
  WEBSITES_EDIT_CONFIGS: 'websites-edit-configs',
  WEBSITES_EDIT_FEES: 'websites-edit-fees',
  WEBSITES_EDIT_GENERAL: 'websites-edit-general',
  WEBSITES_EDIT_MONITORING: 'websites-edit-monitoring',
  WEBSITES_EDIT_PRESIZED_DIVS: 'websites-edit-presized-divs'
})

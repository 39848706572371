import store from '@store'
import views from '@declarations/views'
import endpoints from '@declarations/endpoints'
import statusCode from '@declarations/statusCode'
import actions from '@declarations/actions'
import subjects from '@declarations/subjects'

const AccountsList = () =>
  import(/* webpackChunkName: "accounts-list" */ '@views/accounts/AccountsList.vue')
const AccountsTabs = () =>
  import(/* webpackChunkName: "accounts-tabs" */ '@views/accounts/AccountsTabs.vue')
const AccountsManager = () =>
  import(/* webpackChunkName: "accounts-manager" */ '@views/accounts/AccountsManager.vue')
const AccountsOrganizations = () =>
  import(
    /* webpackChunkName: "accounts-organizations" */ '@views/accounts/AccountsOrganizations.vue'
  )

const appBreadcrumb = [
  {
    text: 'accounts',
    to: { name: views.ACCOUNTS }
  },
  {
    text: () => store.getters['accounts/editedAccountFullName'],
    active: true
  }
]

export default [
  {
    path: `/${views.ACCOUNTS}`,
    name: views.ACCOUNTS,
    component: AccountsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'accounts',
          active: true
        }
      ],
      action: actions.LIST,
      subject: subjects.USER
    }
  },
  {
    path: `/${views.ACCOUNTS}/edit/:id(\\d+)`,
    redirect: { name: views.ACCOUNTS_EDIT_GENERAL },
    name: views.ACCOUNTS_EDIT,
    component: AccountsTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('accounts/fetchItem', `${endpoints.USERS}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.ACCOUNTS_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.ACCOUNTS_EDIT_GENERAL,
        component: AccountsManager,
        meta: {
          appBreadcrumb,
          action: actions.GET,
          subject: subjects.USER
        }
      },
      {
        path: 'organizations',
        name: views.ACCOUNTS_EDIT_ORGANIZATIONS,
        component: AccountsOrganizations,
        meta: {
          appBreadcrumb,
          action: actions.LIST,
          subject: subjects.WEBSITE,
          field: subjects.FIELD_ALLOWED_USERS
        }
      }
    ]
  }
]

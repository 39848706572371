import axios from '@axios'
import endpoints from '@declarations/endpoints'

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('../store/modules/adsTxt').AdsTxt} AdsTxt */

export default {
  /**
   * Fetch ads.txt values
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getAdsTxts (params) {
    return axios.get(endpoints.ADS_TXT, { params })
  },
  /**
   * Get adsTxt values
   * @param {string} adsTxtIRI
   * @returns {AxiosPromise}
   */
  getAdsTxt (adsTxtIRI) {
    return axios.get(adsTxtIRI)
  },
  /**
   * Update adsTxt values
   * @param {string} adsTxtIRI
   * @param {AdsTxt} data
   * @returns {AxiosPromise}
   */
  updateAdsTxt (adsTxtIRI, data) {
    return axios.patch(adsTxtIRI, data)
  },
  /**
     * Create adsTxt
     * @param {AdsTxt} data
     * @returns {AxiosUserResponse}
     */
  createAdsTxt (data) {
    return axios.post(endpoints.ADS_TXT, data)
  },
  /**
   * Fetch bidders list with no ads.txt assigned
   * @returns {AxiosPromise}
   */
  getBiddersWithNoAdsTxt () {
    return axios.get(endpoints.BIDDERS, {
      params: {
        pagination: false,
        exists: {
          defaultAdstxt: false
        }
      }
    })
  }
}

import { isObject } from '@utils'
import * as appAcl from '@fixtures/acl'
import actionsEnums from '@declarations/actions'
import subjectsEnums from '@declarations/subjects'

/**
 * @typedef {Object} Credentials - Credentials submitted by the login form
 * @prop {string} email - User email
 * @prop {string} password - User password
 * @prop {boolean} rememberMyEmail - Remember my email option
 */

/**
 * @typedef {Object} LocalUser
 * @prop {boolean} active
 * @prop {string} email
 * @prop {string} firstName
 * @prop {string} gender
 * @prop {string} lastName
 * @prop {string} phoneNumber
 * @prop {string} plainPassword
 * @prop {Array<string>} [roles]
 */

/**
 * @typedef {Object} APIUserOnly
 * @prop {string} context - Start with @
 * @prop {string} id - Start with @
 * @prop {string} type - Start with @
 * @prop {string} createdAt
 * @prop {string} lastLoginAt
 */

/**
 * @typedef {LocalUser & APIUserOnly} APIUser
 */

/** @typedef {import('/modules/user').LocalUser} User */
/** @typedef {import('../../services/userGroups').userGroups} userGroups */

/**
 * @typedef {Object} State
 * @prop {APIUser} [userData]
 * @prop {userGroups} [userGroups]
 * @prop {boolean} rememberMyEmail
 * @prop {string} rememberedEmail
 * @prop {object} userPermissions
 */

/** @type {State} */
export const state = {
  userData: undefined,
  userGroups: undefined,
  defaultRole: 'Customer',
  rememberMyEmail: true,
  rememberedEmail: '',
  permissions: {}
}

export const getters = {
  /**
   * @param {State} state
   */
  fullName (state) {
    if (!isObject(state.userData)) {
      return 'Anonymous'
    }
    return `${state.userData.firstName} ${state.userData.lastName}`
  },
  /**
   * @param {State} state
   */
  userRole (state) {
    const userRole = state.userGroups
      && state.userGroups.find(userGroup => userGroup?.organization === null && userGroup?.website === null)
    return userRole?.name ?? state.defaultRole
  }
}

export const mutations = {
  /**
   * @param {State} state
   * @param {APIUser} [user]
   */
  setUser (state, user) {
    state.userData = user
  },
  /**
   * @param {State} state
   * @param {Credentials} [credentials]
   */
  setRememberMyEmail (state, credentials) {
    if (!credentials) return

    const { rememberMyEmail, email } = credentials || {}

    if (rememberMyEmail === undefined) return

    state.rememberMyEmail = rememberMyEmail
    state.rememberedEmail = rememberMyEmail ? email : ''
  },
  /**
   * @param {State} state
   * @param {userGroups} [userGroups]
   */
  setUserGroups (state, userGroups) {
    state.userGroups = userGroups
  },
  /**
   * @param {State} state
   * @param {String} userRole
   * @param {Object} permissions
   */
  setPermissions (state, { userRole, permissions }) {
    const role = userRole
    let casbinPolicies = permissions?.filter(p => p.v0 === userRole)
    const appAclUserRole = appAcl[role] ?? []
    // Merge API policies and Front policies
    casbinPolicies = appAclUserRole.concat(casbinPolicies)
    const userPermissions = {
      [actionsEnums.GET]: [],
      [actionsEnums.LIST]: [],
      [actionsEnums.CREATE]: [],
      [actionsEnums.UPDATE]: [],
      [actionsEnums.DELETE]: [],
      [actionsEnums.PUBLISHED]: []
    }

    // Loop with field in permission
    casbinPolicies.forEach(policie => {
      if (policie?.pType === 'p2') {
        const subject = policie?.v1
        const action = policie?.v3
        const field = policie?.v2
        // Check if action is already set, if not create entry
        if (!(action in userPermissions)) userPermissions[action] = []
        // Check if subject is already set, if not create entry
        const actionValues = Object.values(userPermissions[action])
        if (!actionValues.length) {
          userPermissions[action].push({ [subject]: [] })
          // Add field
          userPermissions[action]
            .find(obj => Object.keys(obj).includes(subject))[subject]
            .push(field)
        } else {
          const containsSubject = actionValues.findIndex(obj => Object.keys(obj).includes(subject)) !== -1
          if (!containsSubject) userPermissions[action].push({ [subject]: [] })
          // Add field
          userPermissions[action]
            .find(obj => Object.keys(obj).includes(subject))[subject]
            .push(field)
        }
      }
    })

    const actionWithRestrictingFields = [
      actionsEnums.LIST,
      actionsEnums.GET,
      actionsEnums.CREATE,
      actionsEnums.UPDATE
    ]

    const subjectWithRestrictingFields = [
      subjectsEnums.USER,
      subjectsEnums.ORGANIZATION,
      subjectsEnums.WEBSITE,
      subjectsEnums.WEBSITE_CONFIG
    ]

    // Loop with no field in permission
    casbinPolicies.forEach(policie => {
      if (policie?.pType === 'p') {
        const subject = policie?.v1
        const action = policie?.v2
        // Check if action is already set, if not create entry
        if (!(action in userPermissions)) userPermissions[action] = []
        // Check if subject is already set, if not create entry
        const actionValues = Object.values(userPermissions[action])
        const containsSubject = actionValues.findIndex(obj => Object.keys(obj).includes(subject)) !== -1
        if (!containsSubject) {
          if (subjectWithRestrictingFields.includes(subject) && actionWithRestrictingFields.includes(action)) {
            userPermissions[action].push({ [subject]: ['has_restricted_fields'] })
          } else {
            userPermissions[action].push(subject)
          }
        }
      }
    })

    state.permissions = userPermissions
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('../../services/usersService').UpdateEmailPayload} UpdateEmailPayload */

export const actions = {
  /**
   * Send a PATCH request and update user stored in state with the request response
   * @param {ActionContext} actionContext
   * @param {Object} userData
   * @param {string} userData.userIRI
   * @param {Partial<APIUser>} userData.data
   * @returns {Promise<APIUser>}
   */
  async patchUser ({ commit, state }, { userIRI, data }) {
    const { data: patchedUser } = await this.$services.usersService.updateUser(
      userIRI,
      data
    )
    commit('setUser', patchedUser)
    return state.userData
  },
  /**
   * Send a POST request and update user stored in state with the new email
   * @param {ActionContext} actionContext
   * @param {UpdateEmailPayload} payload
   */
  async updateEmail ({ commit, state }, payload) {
    await this.$services.usersService.updateEmail(payload)
    commit('setUser', {
      ...state.userData,
      email: payload.email
    })

    commit('setRememberMyEmail', {
      rememberMyEmail: state.rememberMyEmail,
      email: payload.email
    })
    return state.userData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import subjects from '@declarations/subjects'
import actions from '@declarations/actions'

const pType = 'p'
const pTypeP2 = 'p2'
const role = 'adtech'

export default [
  // Debug mode
  {
    pType,
    v0: role,
    v1: subjects.DEBUG_MODE,
    v2: actions.GET,
    v3: null
  },
  // Website
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE,
    v2: actions.LIST,
    v3: null
  },
  // Management
  {
    pType,
    v0: role,
    v1: subjects.MANAGEMENT,
    v2: actions.LIST,
    v3: null
  },
  // Website config
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: actions.LIST,
    v3: null
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_TAGGING,
    v3: actions.GET
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_PRESIZED_DIVS,
    v3: actions.GET
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_NOTE,
    v3: actions.GET
  },
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: actions.CREATE,
    v3: null
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_NOTE,
    v3: actions.UPDATE
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_TAGGING,
    v3: actions.UPDATE
  },
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: actions.DELETE,
    v3: null
  }
]

import endpoints from '@declarations/endpoints'
import dashboardKPI from '@declarations/dashboardKPI'

// List of API keys to be changed
export default Object.freeze([
  {
    endpoint: endpoints.KPI_ACTIRISE_REVENUE,
    apiKey: dashboardKPI.REVENUE,
    appKey: dashboardKPI.ACTIRISE_REVENUE
  },
  {
    endpoint: endpoints.KPI_DIRECT_REVENUE,
    apiKey: dashboardKPI.REVENUE,
    appKey: dashboardKPI.DIRECT_REVENUE
  },
  {
    endpoint: endpoints.KPI_FEED_REVENUE,
    apiKey: dashboardKPI.REVENUE,
    appKey: dashboardKPI.FEED_REVENUE
  },
  {
    endpoint: endpoints.KPI_FEED_RPM,
    apiKey: dashboardKPI.RPM,
    appKey: dashboardKPI.FEED_RPM
  },
  {
    endpoint: endpoints.KPI_FEED_RPM_SESSION,
    apiKey: dashboardKPI.RPM,
    appKey: dashboardKPI.FEED_RPM_SESSION
  },
  {
    endpoint: endpoints.KPI_PAGE_VIEWS_WITHOUT_ADS,
    apiKey: dashboardKPI.PAGE_VIEWS,
    appKey: dashboardKPI.PAGE_VIEWS_WITHOUT_ADS
  },
  {
    endpoint: endpoints.KPI_PARTNER_REVENUE,
    apiKey: dashboardKPI.REVENUE,
    appKey: dashboardKPI.PARTNER_REVENUE
  },
  {
    endpoint: endpoints.KPI_RECOVERED_PAGE_VIEWS,
    apiKey: dashboardKPI.PAGE_VIEWS,
    appKey: dashboardKPI.RECOVERED_PAGE_VIEWS
  },
  {
    endpoint: endpoints.KPI_RECOVERED_PAGE_VIEWS_RATE,
    apiKey: dashboardKPI.PAGE_VIEWS,
    appKey: dashboardKPI.RECOVERED_PAGE_VIEWS_RATE
  },
  {
    endpoint: endpoints.KPI_RPM_PAGE_VIEWS,
    apiKey: dashboardKPI.RPM,
    appKey: dashboardKPI.RPM_PAGE_VIEWS
  },
  {
    endpoint: endpoints.KPI_RPM_SESSIONS,
    apiKey: dashboardKPI.RPM,
    appKey: dashboardKPI.RPM_SESSIONS
  }
])

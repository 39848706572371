import axios from '@axios'
import endpoints from '@declarations/endpoints'

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

// For pType value
// p which is used at the resource operation level ({subject, object, action})
// p2 which is used at the resource property level ({subject, object, field, action})

/**
 * @typedef {Object} Policies
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member@type
 * @prop {string} hydraMember.pType - as hydra:member.pType
 * @prop {string} hydraMember.v0 - as hydra:member.v0 => role
 * @prop {string} hydraMember.v1 - as hydra:member.v1 => subject (resource)
 * @prop {string} hydraMember.v2 - as hydra:member.v2 => action or field in relation with pType value
 * @prop {string} hydraMember.v3 - as hydra:member.v3 => => action or null in relation with pType value
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

export default {
  /**
   * Fetch casbin policies values
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getPolicies (params) {
    return axios.get(endpoints.CASBIN_POLICIES, { params })
  }
}

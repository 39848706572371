import views from '@declarations/views'
import config from '@declarations/config'

const TheError404 = () =>
  import(/* webpackChunkName: "the-error-404" */ '@views/notFound/TheError404.vue')
const ResourceNotFound = () =>
  import(/* webpackChunkName: "resource-not-found" */ '@views/notFound/ResourceNotFound.vue')

export default [
  {
    path: `/${views.ERROR_404}`,
    name: views.ERROR_404,
    component: TheError404,
    meta: {
      layout: config.LAYOUT_FULL,
      public: true
    }
  },
  {
    path: `/${views.RESOURCE_NOT_FOUND}/:target?`,
    name: views.RESOURCE_NOT_FOUND,
    component: ResourceNotFound,
    props: route => ({
      target: route.params.target
    }),
    meta: {
      appBreadcrumb: [
        {
          text: 'resourceNotFound',
          active: true
        }
      ]
    }
  }
]

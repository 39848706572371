import { useRoute } from 'vue2-helpers/vue-router'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast as toast } from 'vue-toastification/composition'
import { useI18n } from 'vue-i18n-bridge'
import mergePatch from 'json8-merge-patch'
import axios from 'axios'
import clone from 'just-clone'
import config from '@declarations/config'
import statusCode from '@declarations/statusCode'
import views from '@declarations/views'
import { useDebounceFn } from '@vueuse/core'
import adsTxt from '@declarations/adsTxt'

// eslint-disable-next-line import/prefer-default-export
export const useTogglePasswordVisibility = (
  onIcon = 'EyeIcon',
  offIcon = 'EyeOffIcon'
) => {
  const passwordFieldType = ref('password')

  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
  }

  const passwordToggleIcon = computed(() =>
    (passwordFieldType.value === 'password' ? onIcon : offIcon))

  return {
    passwordFieldType,
    togglePasswordVisibility,
    passwordToggleIcon
  }
}

/**
 * Check if provided value is an object
 * @param {*} value - The value to check
 */
export const isObject = value => value !== null && typeof value === 'object'

/**
 * Display background image views in relation with layout skin
 * @param {string} name
 * @param {Object} isDarkSkin
 * @returns {Object}
 */
export const displayImageLayoutSkin = (name, isDarkSkin) => {
  const imgName = computed(() => (isDarkSkin.value ? `${name}-dark` : name))
  // eslint-disable-next-line global-require,import/no-dynamic-require
  return computed(() => require(`@assets/images/pages/${imgName.value}.svg`))
}

/**
 * Aggregate violation messages of each properties of an Axios error
 * @param {AxiosError} error
 * @returns { Object.<string, string[]> | undefined}
 */
export const getAPIErrors = error => {
  const violations = error?.response?.data?.violations
  if (!violations?.length) return undefined

  return violations.reduce((errors, violation) => {
    const messages = errors[violation.propertyPath] || []
    messages.push(violation.message)
    return {
      ...errors,
      [violation.propertyPath]: messages
    }
  }, {})
}

/**
 * @callback HandleError
 * @param {AxiosError} error
 */
const APIErrorMap = {
  'Access Denied.': {
    i18nKey: 'errors.operationRefused'
  },
  'user_token.token.invalid': {
    i18nKey: 'errors.expiredToken'
  },
  'user_token.token.not_found': {
    i18nKey: 'errors.expiredToken'
  }
}

/** @typedef {import('vue-toastification/dist/types/src/types').ToastOptions} ToastOptions */
/** @typedef {import('vue-toastification/dist/types/src/types').ToastID} ToastID */
/**
 * @callback ShowToast
 * @param {(string | Error)} title
 * @param {string} [variant]
 * @param {string} [text]
 * @param {ToastOptions} [toastOptions]
 * @returns {Promise<ToastID>}
 */
/** @typedef {import('vue-i18n-bridge').useI18n} UseI18n */
/** @typedef {ReturnType<UseI18n>} I18n */
/** @typedef {import('axios').AxiosError} AxiosError */

/**
 * Get the message in the error, translate it if possible or return a generic message
 * @param {AxiosError} error
 * @param {I18n} i18n
 * @returns {string}
 */
const getErrorMessage = (error, i18n) => {
  const errorData = error?.response?.data
  let errorMessage = errorData?.message || errorData?.['hydra:description']

  // Don't use hydra:description if violations data exists
  const violations = errorData?.violations
  if (violations && violations.length) {
    errorMessage = i18n.t('errors.anErrorOccurred')
  }

  if (errorMessage) {
    // Matching for specific keys sent by API
    const { i18nKey } = APIErrorMap[errorMessage] || {}
    if (i18nKey) {
      return i18n.t(i18nKey)
    }

    return i18n.te(`errors.${errorMessage}`)
      ? i18n.t(`errors.${errorMessage}`)
      : errorMessage
  }
  return i18n.t('errors.anErrorOccurred')
}

let listErrorMessages = []

/**
 * Helper for vue toastification
 * @returns {ShowToast}
 */
export const useToast = () => {
  const showToast = toast()
  const i18n = useI18n()
  const route = useRoute()

  return async (title, variant, text, toastOptions) => {
    const isError = axios.isAxiosError(title)

    if (title instanceof Error) {
      // Don't show toast if the error is a navigation failure or an unexpected error
      if (!isError) {
        // eslint-disable-next-line no-console
        if (config.ENVIRONMENT === 'development') console.info(title?.message)
        return undefined
      }
      /**
       * Don't show toast if it's an Axios 401 error caused by an expired JWT
       * and the current route is Login
       */
      if (
        title.response.status === statusCode.UNAUTHORIZED &&
        title.response.data.message === config.JWT_EXPIRED_MESSAGE &&
        route.name === views.LOGIN
      ) {
        return undefined
      }
    }

    const message = isError ? getErrorMessage(title, i18n) : title

    // Do not display multiple toasts if the error message is the same
    if (isError) {
      if (listErrorMessages.includes(message)) return undefined
      listErrorMessages.push(message)

      // Clear list of error messages after delay
      const clearListErrorMessages = useDebounceFn(() => {
        listErrorMessages = []
      }, 10000)
      clearListErrorMessages()
    }

    const color = isError ? 'danger' : variant

    const ToastContent =
      await import(/* webpackChunkName: "toastification-content" */ '@components/partials/ToastificationContent.vue')

    return showToast(
      {
        component: ToastContent.default,
        props: {
          title: message,
          variant: color,
          text
        }
      },
      toastOptions
    )
  }
}

/**
 * Isolate id in iri pattern
 * @param {string} iri
 * @returns {string}
 */
export const displayIdByIri = iri => {
  const lastSlash = iri.lastIndexOf('/')
  return iri.substring(lastSlash + 1)
}

/**
 * Detects modifications of an object and generate an object containing only the
 * properties that differs between the original object and the modified one
 * @param {Object} model
 */
export const useDiff = model => {
  const hasModelBeenEdited = ref(false)
  const modelDiff = ref({})
  const originalFormModel = ref(clone(model))

  watch(
    model,
    (oldModel, newModel) => {
      modelDiff.value = mergePatch.diff(originalFormModel.value, newModel)
      hasModelBeenEdited.value = !!Object.keys(modelDiff.value).length
    },
    {
      deep: true
    }
  )

  const resetDiff = () => {
    originalFormModel.value = clone(model)
    hasModelBeenEdited.value = false
  }

  return {
    originalFormModel,
    hasModelBeenEdited,
    modelDiff,
    model,
    resetDiff
  }
}

/**
 * Verify option label pattern
 * @param {string|number} optionLabel
 * @returns {*|string}
 */
export const translateWhenNaN = optionLabel => {
  const { t } = useI18n()
  return Number(optionLabel) ? optionLabel : t(`optionLabels.${optionLabel}`)
}

/**
 * Translate options label
 * @param {Array} options
 * @param {string} [label=label]
 * @param {boolean} translateOptions
 * @returns {undefined|*}
 */
export const translateOptionsLabel = ({ options, label = 'label', translateOptions }) => {
  if (!Array.isArray(options)) return undefined
  if (translateOptions === false) return options

  const { t } = useI18n()
  return options.map(option => {
    if (typeof option === 'string') {
      return t(option)
    }
    const optionLabel = option[label]
    return {
      ...option,
      [label]: translateWhenNaN(optionLabel)
    }
  })
}

/**
 * Replace empty string with null in object
 * @param {Object} obj
 * @returns {Object}
 */
export const replaceEmptyValueToNull = obj => Object.keys(obj).reduce((acc, key) => {
  acc[key] = obj[key] === '' ? null :
    obj[key]
  return acc
}, {})

/**
 * Display default content Bootstrap vue confirm modal
 * see https://bootstrap-vue.org/docs/components/modal#modal
 * @param {string|null} [title=null]
 * @param {string|null} [message=null]
 * @param {boolean} [isDelete=false]
 * @returns {Object}
 */
export const msgBoxConfirmContent = (
  {
    title = null,
    message = null,
    isDelete = false
  }
) => {
  const { t, te } = useI18n()

  return computed(() => ({
    message: message || t('modal.defaultContentConfirm'),
    options: {
      title: title && te(title) ? t(title) : title,
      okTitle: !isDelete ? t('buttons.validate') : t('buttons.delete'),
      cancelTitle: t('buttons.cancel'),
      okVariant: !isDelete ? 'info' : 'danger',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      headerCloseLabel: t('buttons.close')
    }
  }))
}

/**
 * Get current error elements for the active form
 * @returns {Array} Array of DOM elements
 */
export const getCurrentFormErrors = () =>
  [...document.querySelectorAll('[data-app-form] .invalid-feedback, [data-app-form] [data-error]')]
    .filter(element => element.offsetParent !== null) // Filters display: none elements

/**
 * Display variant color in relation with value
 * @param {number} value
 * @param {Array} [interval=[50,70]]
 * @param {Array} [exclude=[]]
 * @return {string}
 */
export const getVariant = ({ value, interval = [50, 70], exclude = [] }) => {
  switch (true) {
    case value > interval[0] && value < interval[1] && !exclude.includes('warning'):
      return 'warning'
    case value <= interval[0] && !exclude.includes('danger'):
      return 'danger'
    case value >= interval[1] && !exclude.includes('success'):
      return 'success'
    default:
      return ''
  }
}

/**
 * Capitalize first letter in string
 * @param {string} string
 * @returns {string}
 */
export const capitalizeFirstLetter = string => string[0].toUpperCase() + string.slice(1)

/**
 * Group and sum values in array object
 * @param {Array} arr
 * @param {Array} groupKeys
 * @param {Array} sumKeys
 * @returns {Array}
 */
export const groupAndSum = (arr, groupKeys, sumKeys) =>
  Object.values(
    arr.reduce((acc, curr) => {
      const group = groupKeys.map(k => curr[k]).join('-')
      acc[group] =
        acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])))
      // eslint-disable-next-line no-return-assign
      sumKeys.forEach(k => acc[group][k] += curr[k])
      return acc
    }, {})
  )

/**
 * Format lines as ace annotations
 * @param {Object} lines
 * @param {string} type
 * @param {function} formatText
 * @returns {Array} formatted lines
 */
const formatLine = (lines, type, formatText = v => v) => Object.entries(lines || {})
  .map(([row, value]) => ({
    row: Number(row) - 1,
    text: formatText(value),
    type
  }))

/**
 * Helper to display adsTxtViolations
 * @param {Object} options - keys: "error" (undefined to clean markers), "editor" (editor component), "errorKey" used for editor loop
 * @returns {Object} violations
 */
export const adsTxtViolations = ({ error = { response: { data: { violations: [{ message: '{}' }] } } }, editor, errorKey = null }) => {
  let messages = JSON.parse(error.response.data.violations[0].message)
  if (errorKey in messages) {
    messages = messages[errorKey]
  }

  const notices = formatLine(messages[adsTxt.VIOLATIONS.NOTICES], 'info')
  const errors = formatLine(messages[adsTxt.VIOLATIONS.ERRORS], 'error')
  const warnings = formatLine(messages[adsTxt.VIOLATIONS.WARNINGS], 'warning')
  const duplicates = formatLine(messages[adsTxt.VIOLATIONS.DUPLICATES], 'warning', ([line]) => [`This value is duplicated at line ${line}`])

  editor.setMarkers([...notices, ...errors, ...warnings, ...duplicates])

  return {
    [adsTxt.VIOLATIONS.NOTICES]: notices,
    [adsTxt.VIOLATIONS.ERRORS]: errors,
    [adsTxt.VIOLATIONS.WARNINGS]: warnings,
    [adsTxt.VIOLATIONS.DUPLICATES]: duplicates
  }
}

/**
 * Rename keys in array object
 * @param {Object} keysMap - contains key/value pairs of your old/new object keys
 * @param {Object} obj - is the object to be changed
 * @returns {Object}
 */
export const renameKeys = (keysMap, obj) => Object
  .keys(obj)
  .reduce((acc, key) => ({
    ...acc,
    ...{ [keysMap[key] || key]: obj[key] }
  }), {})

/**
 * Calculate percent
 * @param {number} value
 * @param {string|number} total
 * @returns {string}
 */
export const calculatePercent = (value, total) => (100 * (Number(value) / total)).toFixed(2)

/* eslint-disable no-underscore-dangle */

import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import { watch } from '@vue/composition-api'
import config from '@declarations/config'

/**
 * Setup Matomo tracker
 * @param {object} store
 * @param {object} router
 */
export default ({ store, router }) => {
  // Get user infos from store
  const getUserInfo = () => {
    const role = store.state.user?.userData?.type?.code
    const isAdmin = role !== config.CUSTOMER_ROLE

    return {
      role,
      isAdmin,
      userId: store.state.user?.userData?.id,
      publisherName: store.state.dashboard.publisherItems
        ?.filter(item => item.isHeader)
        ?.map(item => item.domain)
    }
  }

  // Init Matomo tracker
  let trackerInstalled = false
  const initTracker = () => {
    const {
      userId, role, isAdmin, publisherName
    } = getUserInfo()

    Vue.use(VueMatomo, {
      host: 'https://matomo.platform-k8s.bricks-co.com/',
      siteId: 2,
      router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: true,
      heartBeatTimerInterval: 15,
      debug: false,
      userId,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: [
        ['setCustomDimension', 1, role],
        (isAdmin ? null : ['setCustomDimension', 2, publisherName])
      ],
      trackSiteSearch: false,
      crossOrigin: undefined
    })

    trackerInstalled = true
  }

  // Track changes of user and update Matomo variables
  watch(getUserInfo, ({
    userId, role, isAdmin, publisherName
  }) => {
    if (!isAdmin && !publisherName) return // Skip init while publishers are not fetched yet

    if (!trackerInstalled) initTracker() // Init tracker
    else if (!userId) { // Sign out user
      window._paq.push(['resetUserId'])
      window._paq.push(['deleteCustomDimension', 1])
      window._paq.push(['deleteCustomDimension', 2])
    } else { // Sign in user
      window._paq.push(['setUserId', userId])
      window._paq.push(['setCustomDimension', 1, role])
      if (!isAdmin) window._paq.push(['setCustomDimension', 2, publisherName])
      window._paq.push(['ping']) // Make sure infos are updated
    }
  }, {
    deep: true,
    immediate: true
  })
}

//
//
//
//
//
//
import { inject, ref, onMounted, watch } from '@vue/composition-api';
import { useI18n } from 'vue-i18n-bridge';
import { useState } from '@store';
export default {
  name: 'DebugDisclaimer',
  setup: function setup() {
    var _inject = inject('$enums'),
        scss = _inject.scss;

    var _useI18n = useI18n(),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var _useState = useState('app', ['debugMode']),
        debugMode = _useState.debugMode; // Create canvas


    var canvas = document.createElement('canvas');
    var canvasWidth = 800;
    var canvasHeight = 80;
    canvas.setAttribute('width', canvasWidth);
    canvas.setAttribute('height', canvasHeight);
    var context = canvas.getContext('2d');
    context.fillStyle = scss.APP_FONT_COLOR;
    context.font = '44px sans-serif';
    context.fillText(t('common.confidential').toUpperCase(), 0, canvasHeight / 2);
    var debugDisclaimer = ref(null);
    onMounted(function () {
      if (debugDisclaimer.value) {
        debugDisclaimer.value.style.backgroundImage = "url(".concat(canvas.toDataURL('image/png'), ")");
      }
    });
    watch(locale, function () {
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.fillText(t('common.confidential').toUpperCase(), 0, canvasHeight / 2);
      debugDisclaimer.value.style.backgroundImage = "url(".concat(canvas.toDataURL('image/png'), ")");
    });
    return {
      debugMode: debugMode,
      debugDisclaimer: debugDisclaimer
    };
  }
};
import { defineAbility } from '@casl/ability'
import store from '@store'
import { isObject } from '@utils'

export const useAbility = () => defineAbility(can => {
  const { permissions } = store.state.user

  Object.keys(permissions).map(action => permissions[action].map(subject => {
    // No fields in subject
    if (!isObject(subject)) return can(action, subject)

    // Fields in subject
    return Object.keys(subject).map(item => can(action, item, subject[item]))
  }))
})

export const _ = null

import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import endpoints from '@declarations/endpoints'
import statusCode from '@declarations/statusCode'
import configEnums from '@declarations/config'
import store from '@store'
import { i18n } from '@i18n'

const axiosInstance = axios.create({
  baseURL: endpoints.BASE_URL_API,
  headers: {
    common: {
      Accept: 'application/ld+json, application/json',
      'Accept-Language': i18n.global.locale.value
    }
  },
  paramsSerializer: params => qs.stringify(params)
})

axiosInstance.interceptors.request.use(
  config => {
    // Apply correct Content-type header in case of a PATCH request
    if (config.method === 'patch') {
      // eslint-disable-next-line no-param-reassign
      config.headers['Content-Type'] = 'application/merge-patch+json'
    }
    return config
  },
  error => Promise.reject(error)
)

/** Redirect to login page when request fail with a 401 response */
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    // TODO for legacy
    const errorCode = configEnums.LEGACY ? statusCode.TOKEN_EXPIRED : statusCode.UNAUTHORIZED
    if (error?.response?.status === errorCode) {
      await store.dispatch('auth/logout', true)
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosInstance

/**
 * @param {string} header
 * @param {string} locale
 */
export const setAxiosHeader = (header, locale) =>
  Reflect.set(axiosInstance.defaults.headers.common, header, locale)

/**
 * @param {string} header
 */
export const removeAxiosHeader = header =>
  Reflect.deleteProperty(axiosInstance.defaults.headers.common, header)

const defaultBounceURLParams = '?id={id}&token={token}'

/**
 * Build bounce url sent to the API and used for email redirection
 * @param {string} path
 * @param {string} [URLParams]
 * @returns {string} - Bounce url
 */
export const getBounceUrl = (path, URLParams = defaultBounceURLParams) =>
  `${path}${URLParams}`

export default axiosInstance

import axios from '@axios'
import endpoints from '@declarations/endpoints'

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('../store/modules/bidders').Bidder} Bidder */

export default {
  /**
   * Fetch bidders values
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getBidders (params) {
    return axios.get(endpoints.BIDDERS, { params })
  },
  /**
   * Get bidder values
   * @param {string} bidderIRI
   * @returns {AxiosPromise}
   */
  getBidder (bidderIRI) {
    return axios.get(bidderIRI)
  },
  /**
   * Update bidder values
   * @param {string} bidderIRI
   * @param {Bidder} data
   * @returns {AxiosPromise}
   */
  updateBidder (bidderIRI, data) {
    return axios.patch(bidderIRI, data)
  },
  /**
   * Create bidder
   * @param {Bidder} data
   * @returns {AxiosUserResponse}
   */
  createBidder (data) {
    return axios.post(endpoints.BIDDERS, data)
  }
}

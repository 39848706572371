import {
  stateAPI, mutationsAPI, actionsAPI, gettersAPI
} from '@store/modules/api'
import endpoints from '@declarations/endpoints'

export const state = {
  endpoint: endpoints.CURRENCIES,
  ...stateAPI
}
export const mutations = {
  ...mutationsAPI
}
export const getters = {
  ...gettersAPI
}
export const actions = {
  ...actionsAPI
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

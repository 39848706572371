import axios from '@axios'
import endpoints from '@declarations/endpoints'

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

/**
 * @typedef {Object} UserGroups
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member@type
 * @prop {string} hydraMember.name - as hydra:member.name
 * @prop {string} hydraMember.organization - as hydra:member.organization
 * @prop {string} hydraMember.user - as hydra:member.user
 * @prop {string} hydraMember.website - as hydra:member.website
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

export default {
  /**
   * Fetch userGroups values
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getUserGroups (params) {
    return axios.get(endpoints.USER_GROUPS, { params })
  }
}

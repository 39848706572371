import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@declarations/table'

/**
 * @typedef {Object} OrganizationsType
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {string} value
 */

/**
 * @typedef {Object} OrganizationsCountry
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {string} code
 * @prop {string} name
 */

/**
 * @typedef {Object} Organizations
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member@type
 * @prop {string} hydraMember.address - as hydra:member.address
 * @prop {string} hydraMember.address2 - as hydra:member.address2
 * @prop {string} hydraMember.city - as hydra:member.city
 * @prop {OrganizationsCountry} hydraMember.country - as hydra:member.country
 * @prop {string} hydraMember.name - as hydra:member.name
 * @prop {string} hydraMember.postalCode - as hydra:member.postalCode
 * @prop {OrganizationsType} hydraMember.type - as hydra:member.type
 * @prop {array} hydraMember.users - as hydra:member.users
 * @prop {string} hydraMember.vat - as hydra:member.vat
 * @prop {string} hydraMember.website - as hydra:member.website
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} Organization
 * @prop {string} address
 * @prop {string} address2
 * @prop {string} city
 * @prop {OrganizationsCountry} country
 * @prop {string} name
 * @prop {string} postalCode
 * @prop {OrganizationsType} type
 * @prop {array} users
 * @prop {string} vat
 * @prop {string} website
 */

/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {Organizations[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {object} tableColumnFilters
 * @prop {Organization} editedItem
 */

/** @type {State} */
export const state = {
  service: {
    name: 'organizationsService',
    getItemsMethod: 'getOrganizations',
    getItemMethod: 'getOrganization',
    patchItemMethod: 'updateOrganization'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

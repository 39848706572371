import dashboard from '@declarations/dashboard'
import config from '@declarations/config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import getDates from '@utils/dashboardDates'

dayjs.extend(utc)
dayjs.extend(timezone)

const dayjsFormat = 'YYYY-MM-DD'
const dayjsFormatHourMinute = 'YYYY-MM-DDTHH:mm'

/**
 * Formatter date param
 * @param {string} tzParam
 * @returns {string}
 */
const roundDate = tzParam => {
  const now = dayjs()
    .tz(tzParam)
    .format(dayjsFormatHourMinute)
  const coeff = 1000 * 60 * 5
  const date = new Date(now)
  const rounded = new Date(Math.floor(date.getTime() / coeff) * coeff)

  return dayjs(rounded).format(dayjsFormatHourMinute)
}

/**
 * Extract and format params from store state
 * @param {Object} state - Store state
 * @param {Array} keys - Keys to extract
 * @param {string|null} [customParams=null] - Specific parameters
 * @returns {Object} params
 */
// eslint-disable-next-line complexity
export default (state, keys, customParams = null) => {
  const params = Object.fromEntries(keys
    .map(key => [key, state[key]])
    .filter(([, value]) => value))

  const locale = localStorage.getItem(config.STORAGE_I18N) || config.DEFAULT_LANGUAGE

  const {
    defaultRange,
    today,
    yesterday,
    startLast7Days,
    endLastDay,
    startLastWeek,
    endLastWeek,
    startOfMonth,
    startLast30Days,
    startLastMonth,
    endLastMonth
  } = getDates({ locale: { value: locale } }).value

  // Enforce default on timezone
  if (!params[dashboard.TIMEZONE]) params[dashboard.TIMEZONE] = dashboard.TIMEZONE_DEFAULT

  // Format is_customer param(boolean) for API pattern
  params[dashboard.IS_CUSTOMER] = !params[dashboard.IS_CUSTOMER] ? 0 : 1

  // Dates params
  switch (true) {
    // SHORTCUT LAST 30 MINUTES
    case params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.NOW_30M:
      params[dashboard.START_DATE] =
        dayjs()
          .subtract(state.lastDurationMinutes, 'minutes')
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormatHourMinute)
      customParams === dashboard.FILTERS
        ? params[dashboard.END_DATE] = roundDate(params[dashboard.TIMEZONE])
        : delete params[dashboard.END_DATE]
      break

    // SHORTCUT TODAY SO FAR OR CURRENT DAY
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.TODAY_SO_FAR)
    || (params[dashboard.START_DATE] === today && params[dashboard.END_DATE] === today):
      params[dashboard.START_DATE] =
        dayjs()
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormat)
      customParams === dashboard.FILTERS
        ? params[dashboard.END_DATE] = roundDate(params[dashboard.TIMEZONE])
        : delete params[dashboard.END_DATE]
      break

    // SHORTCUT YESTERDAY OR YESTERDAY
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.YESTERDAY)
    || (params[dashboard.START_DATE] === yesterday && params[dashboard.END_DATE] === yesterday):
      params[dashboard.START_DATE] =
        dayjs()
          .subtract(1, 'day')
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormat)
      params[dashboard.END_DATE] =
        dayjs()
          .subtract(1, 'day')
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormat)
      break

    // SHORTCUT LAST 7 DAYS
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.LAST_7_DAYS):
      params[dashboard.START_DATE] = startLast7Days.format(dayjsFormat)
      params[dashboard.END_DATE] = endLastDay.format(dayjsFormat)
      break

    // SHORTCUT LAST WEEK
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.LAST_WEEK):
      params[dashboard.START_DATE] = startLastWeek.format(dayjsFormat)
      params[dashboard.END_DATE] = endLastWeek.format(dayjsFormat)
      break

    // SHORTCUT THIS MONTH
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.THIS_MONTH):
      params[dashboard.START_DATE] = startOfMonth.tz(params[dashboard.TIMEZONE]).format(dayjsFormat)
      params[dashboard.END_DATE] =
        dayjs()
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormat)
      break

    // SHORTCUT LAST 30 DAYS
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.LAST_30_DAYS):
      params[dashboard.START_DATE] = startLast30Days.format(dayjsFormat)
      params[dashboard.END_DATE] = endLastDay.format(dayjsFormat)
      break

    // SHORTCUT LAST MONTH
    case (params[dashboard.SHORTCUT_DATE] && params[dashboard.SHORTCUT_DATE] === dashboard.LAST_MONTH):
      params[dashboard.START_DATE] = startLastMonth.format(dayjsFormat)
      params[dashboard.END_DATE] = endLastMonth.format(dayjsFormat)
      break

    // WHEN END_DATE IS TODAY OR YESTERDAY
    case params[dashboard.END_DATE] === today || params[dashboard.END_DATE] === yesterday:
      params[dashboard.START_DATE] =
        dayjs(params[dashboard.START_DATE])
          .tz(params[dashboard.TIMEZONE])
          .format(dayjsFormat)
      if (params[dashboard.END_DATE] === yesterday) {
        params[dashboard.END_DATE] =
          dayjs(params[dashboard.END_DATE])
            .endOf('day')
            .tz(params[dashboard.TIMEZONE])
            .format(dayjsFormat)
      } else {
        params[dashboard.END_DATE] =
          dayjs()
            .tz(params[dashboard.TIMEZONE])
            .format(dayjsFormat)
      }
      break

    // DEFAULT
    default:
      // Enforce default on date
      if (!params[dashboard.START_DATE]) {
        params[dashboard.START_DATE] =
          dayjs(defaultRange.value()[0])
            .tz(params[dashboard.TIMEZONE])
            .format(dayjsFormatHourMinute)
      }
      if (!params[dashboard.END_DATE]) {
        params[dashboard.END_DATE] =
          dayjs(defaultRange.value()[1])
            .endOf('day')
            .tz(params[dashboard.TIMEZONE])
            .format(dayjsFormatHourMinute)
      }
  }

  // Custom params
  if (customParams === 'last24h') {
    // Last 24h (DashboardHomePage component)
    params[dashboard.START_DATE] =
      dayjs()
        .subtract(1, 'day')
        .tz(params[dashboard.TIMEZONE])
        .format(dayjsFormat)
    if (params[dashboard.END_DATE]) delete params[dashboard.END_DATE]
  } else if (customParams === 'partner_revenue') {
    // MonetCard component
    params[dashboard.START_DATE] =
      dayjs()
        .tz(params[dashboard.TIMEZONE])
        .format(dayjsFormat)
  } else if (customParams === 'yesterday_partner_revenue') {
    // MonetCard component
    params[dashboard.START_DATE] =
      dayjs()
        .subtract(1, 'day')
        .tz(params[dashboard.TIMEZONE])
        .format(dayjsFormat)
    params[dashboard.END_DATE] =
      dayjs()
        .subtract(1, 'day')
        .tz(params[dashboard.TIMEZONE])
        .format(dayjsFormatHourMinute)
  }

  // Delete not necessary param for API request
  if (params[dashboard.SHORTCUT_DATE]) delete params[dashboard.SHORTCUT_DATE];

  // Split array params
  [
    dashboard.PUBLISHERS,
    dashboard.DEVICE,
    dashboard.ORIGIN_SOURCE,
    dashboard.COUNTRY,
    dashboard.CUSTOM_VAR_1,
    dashboard.CUSTOM_VAR_2,
    dashboard.CUSTOM_VAR_3,
    dashboard.CUSTOM_VAR_4,
    dashboard.CUSTOM_VAR_5,
    dashboard.CRAWLER
  ].forEach(key => {
    // If multiples values for the same key, display array for API request
    if (params[key]) params[key] = params[key]?.split(dashboard.SEPARATOR)
  })

  return params
}

let enabled = false
let data
let keysToPopulate

/**
 * Populate URL search with parameters from data
 */
const refreshURL = () => {
  const url = new URL(window.location.href)

  Object.entries(data).forEach(([key, value]) => {
    if (keysToPopulate && keysToPopulate.indexOf(key) === -1) return
    value !== null ? url.searchParams.set(key, value) : url.searchParams.delete(key)
  })

  if (window.location.href !== url.href) window.history.pushState({}, '', url)
}

/**
 * Enable URL storage and populate URL
 * @param {Array} keys - Keys to populate (if undefined, populate all keys)
 */
export const enable = keys => {
  keysToPopulate = keys
  enabled = true
  refreshURL()
}

/**
 * Disable URL storage and clear URL
 */
export const disable = () => {
  enabled = false
  window.history.pushState({}, '', new URL(window.location.href))
}

/**
 * Return an object that follows standard storage interface (getItem / setItem)
 * Flatten provided module data (other modules are ignored)
 * @param {string} module - Module to flatten
 * @returns {Object}
 */
export const storage = module => ({
  /**
   * Get stored data from URL search as JSON string
   * @returns {string}
   */
  getItem () {
    const params = new URLSearchParams(window.location.search)
    return JSON.stringify({
      [module]: Object.fromEntries(params.entries())

    })
  },
  /**
   * Store data in memory (data variable) and populate URL if enabled
   * @param {string} key (ignored as the module is flat)
   * @param {Object} item item to store, must contain a [module] key
   */
  setItem (key, item) {
    data = JSON.parse(item)[module]
    if (enabled) refreshURL()
  }
})

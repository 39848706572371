import axios, { getBounceUrl } from '@axios'
import endpoints from '@declarations/endpoints'
import config from '@declarations/config'
import genders from '@fixtures/legacy/genders.json'

/** @typedef {import('../store/modules/user').LocalUser} User */
/** @typedef {import('../store/modules/user').APIUser} APIUser */
/** @typedef {User & {plainPassword: string}} UserRegister */
/**
 * @template T
 * @typedef {import('axios').AxiosPromise<T>} AxiosPromiseGeneric
 */
/** @typedef {import('axios').AxiosPromise} AxiosPromise  */
/** @typedef {AxiosPromiseGeneric<APIUser>} AxiosUserResponse */
/** @typedef {{id: string, token: string}} ValidationPayload */
/** @typedef {ValidationPayload & {plainPassword: string}} PasswordResetPayload */
/**
 * @typedef {Object} UpdateEmailPayload
 * @prop {string} payload.user - Current user IRI
 * @prop {string} payload.email - New user email
 * @prop {string} payload.password - Current user password
 */
/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */

const registrationValidationUrl = getBounceUrl('/validate')

const resetPasswordUrl = getBounceUrl('/reset-password')

const getUserIRI = id => `${endpoints.USERS}/${id}`

export default {
  /**
   * User registration
   * @param {UserRegister} user
   * @param {string|null} [invitationIRI=null]
   * @returns {AxiosUserResponse}
   */
  register (user, invitationIRI = null) {
    // TODO for legacy
    if (config.LEGACY) {
      Object.assign(user, {
        idUserCivility: user.gender === genders[0]['@id'] ? 1 : 2,
        password: user.plainPassword,
        phone: user.phoneNumber,
        gender: undefined,
        plainPassword: undefined,
        preferredLocale: undefined,
        phoneNumber: undefined
      })
    }

    const endpoint = invitationIRI
      ? endpoints.USERS_INVITATION.replace('{{invitationIRI}}', invitationIRI)
      : endpoints.USERS

    return axios.post(endpoint, user, {
      headers: {
        [config.BOUNCE_HEADER]: registrationValidationUrl
      }
    })
  },
  /**
   * User registration validation
   * @param {ValidationPayload} payload
   * @returns {AxiosPromise}
   */
  validateRegister ({ id, token }) {
    const user = getUserIRI(id)
    return axios.post(endpoints.VALIDATE_REGISTER, {
      user,
      token
    })
  },
  /**
   * Request user password reset
   * @param {string} email
   * @returns {AxiosPromise}
   */
  requestPasswordReset (email) {
    return axios.post(
      endpoints.FORGOTTEN_PASSWORD,
      { email },
      {
        headers: {
          [config.BOUNCE_HEADER]: resetPasswordUrl
        }
      }
    )
  },
  /**
   * User password change
   * @param {PasswordResetPayload} payload
   * @returns {AxiosPromise}
   */
  resetPassword ({ id, token, plainPassword }) {
    const user = getUserIRI(id)
    return axios.post(endpoints.RESET_PASSWORD, {
      plainPassword,
      user,
      token
    })
  },
  /**
   * User email update
   * @param {UpdateEmailPayload} payload
   * @returns {AxiosPromise}
   */
  updateEmail ({ user, email, password }) {
    return axios.post(endpoints.UPDATE_EMAIL, {
      user,
      email,
      password
    })
  },
  /**
   * User password update
   * @param {Object} payload
   * @param {string} payload.user - Current user IRI
   * @param {string} payload.currentPassword
   * @param {string} payload.newPassword
   * @returns {AxiosPromise}
   */
  updatePassword ({ user, currentPassword, newPassword }) {
    return axios.post(endpoints.UPDATE_PASSWORD, {
      user,
      currentPassword,
      newPassword
    })
  },
  /**
   * Get users data
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getUsers (params) {
    return axios.get(endpoints.USERS, { params })
  },
  /**
   * Get user data
   * @param {string} userIRI
   * @returns {AxiosPromise}
   */
  getUser (userIRI) {
    return axios.get(userIRI)
  },
  /**
   * Update user data
   * @param {string} userIRI
   * @param {User} data
   * @returns {AxiosPromise}
   */
  updateUser (userIRI, data) {
    // TODO for legacy
    return config.LEGACY ? axios.put(userIRI, data) : axios.patch(userIRI, data)
  },
  /**
   * Update user preferred locale
   * @param {string} userIRI
   * @param {string} locale
   */
  changePreferredLocale (userIRI, locale) {
    return axios.patch(userIRI, { preferredLocale: locale }).catch(error => error)
  }
}

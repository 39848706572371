import actions from '@declarations/actions'
import adsTxt from '@declarations/adsTxt'
import apiTokenStatus from '@declarations/apiTokenStatus'
import bidderStatus from '@declarations/bidderStatus'
import brandLogos from '@declarations/brandLogos'
import browsers from '@declarations/browsers'
import config from '@declarations/config'
import configStatus from '@declarations/configStatus'
import cornerTemplate from '@declarations/cornerTemplate'
import dashboard from '@declarations/dashboard'
import dashboardKPI from '@declarations/dashboardKPI'
import devices from '@declarations/devices'
import devicesIcons from '@declarations/devicesIcons'
import endpoints from '@declarations/endpoints'
import feedTemplate from '@declarations/feedTemplate'
import hierarchy from '@declarations/hierarchy'
import icons from '@declarations/icons'
import matchingApiAppKeys from '@declarations/matchingApiAppKeys'
import organizationTypeValues from '@declarations/organizationTypeValues'
import overview from '@declarations/overview'
import placeholder from '@declarations/placeholder'
import scss from '@declarations/scss'
import slotTemplates from '@declarations/slotTemplates'
import statusCode from '@declarations/statusCode'
import subjects from '@declarations/subjects'
import table from '@declarations/table'
import views from '@declarations/views'

export default Object.freeze({
  actions,
  adsTxt,
  apiTokenStatus,
  bidderStatus,
  brandLogos,
  browsers,
  config,
  configStatus,
  cornerTemplate,
  dashboard,
  dashboardKPI,
  devices,
  devicesIcons,
  endpoints,
  feedTemplate,
  hierarchy,
  icons,
  matchingApiAppKeys,
  organizationTypeValues,
  overview,
  placeholder,
  scss,
  slotTemplates,
  statusCode,
  subjects,
  table,
  views
})

import views from '@declarations/views'

const TheHomePage = () =>
  import(/* webpackChunkName: "the-homepage" */ '@views/TheHomePage.vue')

export default [
  {
    path: '',
    name: views.HOMEPAGE,
    component: TheHomePage,
    meta: {
      appBreadcrumb: [
        {
          text: 'dashboard',
          active: true
        }
      ]
    }
  }
]

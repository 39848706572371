import axios from '@axios'
import endpoints from '@declarations/endpoints'

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {object} WebsiteParam
 * @property {string} website
 */

/** @typedef {TableFiltersState & WebsiteParam} GetWebsiteConfigsParams */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('../store/modules/websiteConfigsList').WebsiteConfig} WebsiteConfig */

export default {
  /**
   * Fetch website configs values
   * @param {GetWebsiteConfigsParams} params
   * @returns {AxiosPromise}
   */
  getWebsiteConfigs (params) {
    return axios.get(endpoints.WEBSITE_CONFIGS, { params })
  },
  /**
   * Fetch website config values
   * @param {string} websiteConfigIRI
   * @returns {AxiosPromise}
   */
  getWebsiteConfig (websiteConfigIRI) {
    return axios.get(websiteConfigIRI)
  },
  /**
   * Update website config
   * @param {string} websiteConfigIRI
   * @param {WebsiteConfig} data
   * @returns {AxiosPromise}
   */
  updateWebsiteConfig (websiteConfigIRI, data) {
    return axios.patch(websiteConfigIRI, data)
  },
  /**
   * Lock / Unlock website config (use fetch for keepalive option)
   * @param {*} websiteConfigIRI
   * @param {boolean} lock
   * @returns {Promise}
   */
  lockWebsiteConfig (websiteConfigIRI, lock = true) {
    const lockEndpoint = endpoints.WEBSITE_CONFIGS_LOCK.replace('{{websiteConfigIRI}}', websiteConfigIRI)
    return fetch(axios.defaults.baseURL + lockEndpoint, {
      method: 'PATCH',
      headers: {
        ...axios.defaults.headers.common,
        'content-type': 'application/merge-patch+json'
      },
      mode: 'cors',
      body: JSON.stringify({ lock }),
      keepalive: true
    })
  },
  /**
   * Delete website config
   * @param {string} websiteConfigIRI
   * @returns {AxiosPromise}
   */
  deleteWebsiteConfig (websiteConfigIRI) {
    return axios.delete(websiteConfigIRI)
  },
  /**
   * Duplicate Config
   * @param {string} websiteConfigIRI
   * @returns {AxiosPromise}
   */
  duplicateWebsiteConfig (websiteConfigIRI) {
    return axios.post(endpoints.DUPLICATED_WEBSITE_CONFIGS, { websiteConfig: websiteConfigIRI })
  },
  /**
   * Get website config ads.txt values
   * @param {string} websiteConfigIRI
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getWebsiteConfigAdsTxt (websiteConfigIRI, params) {
    return axios.get(endpoints.WEBSITE_CONFIGS_ADS_TXT.replace('{{websiteConfigIRI}}', websiteConfigIRI), { params })
  },
  /**
   * Update website config ads.txt
   * @param {string} websiteConfigIRI
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  updateWebsiteConfigAdsTxt (websiteConfigIRI, data) {
    return axios.patch(endpoints.WEBSITE_CONFIGS_ADS_TXT.replace('{{websiteConfigIRI}}', websiteConfigIRI), {
      adstxt: data
    })
  }
}

// See currencyEndpoints array for currency endpoints list

import config from '@declarations/config'

const analyticPath = '/data/analytic'

// TODO for legacy
export default Object.freeze({
  ADS_TXT: '/adstxt',
  API_TOKENS: '/api_tokens',
  // TODO for legacy
  AUTH: config.LEGACY ? '/users/auth' : '/authentication_token',
  BASE_URL_API: config.LEGACY ? process.env.VUE_APP_API_URL_V1 : process.env.VUE_APP_BASE_URL_API,
  BASE_URL_FRONT: process.env.VUE_APP_BASE_URL_FRONT,
  BIDDERS: '/bidders',
  CASBIN_POLICIES: '/casbin_policies',
  COUNTRIES: '/countries',
  CURRENCIES: '/currencies',
  DUPLICATED_WEBSITE_CONFIGS: '/duplicated_website_configs',
  // TODO for legacy
  FORGOTTEN_PASSWORD: config.LEGACY ? '/users/password/lost' : '/forgotten_passwords',
  GENDERS: '/genders',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_EXCLUSIONS: '/organization_exclusions',
  ORGANIZATION_INVITATIONS: '/organization_invitations',
  ORGANIZATION_TYPES: '/organization_types',
  RESET_PASSWORD: '/reset_passwords',
  UPDATE_EMAIL: '/updated_email_addresses',
  UPDATE_PASSWORD: '/updated_passwords',
  USER_GROUPS: '/user_groups',
  USERS: '/users',
  USERS_INVITATION: '/users/post-registration/{{invitationIRI}}',
  VALIDATE_REGISTER: '/validated_email_addresses',
  WEBSITE_ALLOWED_USERS: '/website_allowed_users',
  WEBSITE_CONFIGS: '/website_configs',
  WEBSITE_CONFIGS_ADS_TXT: '{{websiteConfigIRI}}/adstxt',
  WEBSITE_CONFIGS_LOCK: '{{websiteConfigIRI}}/lock',
  WEBSITES: '/websites',
  WEBSITES_ADS_TXT_MONITORING: '{{websiteIRI}}/adstxt_monitorings',
  WEBSITES_SCRAP: '{{websiteIRI}}/scrap',
  // ANALYTICS DIMENSIONS
  ANALYTICS_DIMENSIONS: '/data/dimensions/{{dimension}}',
  // ANALYTICS AVG
  AVG_BOUNCE_RATE_CMP: `${analyticPath}/network/eu_bounce_rate`,
  AVG_CLS: `${analyticPath}/network/cls`,
  AVG_CONSENT_RATE: `${analyticPath}/network/eu_consent_rate`,
  AVG_CRAWLER_RATE: `${analyticPath}/network/crawler_rate`,
  AVG_FCP: `${analyticPath}/network/fcp`,
  AVG_FID: `${analyticPath}/network/fid`,
  AVG_INP: `${analyticPath}/network/inp`,
  AVG_LCP: `${analyticPath}/network/lcp`,
  AVG_PAGE_VIEWS_WITHOUT_ADS_RATE: `${analyticPath}/network/page_views_without_ads_rate`,
  AVG_SPENT_TIME: `${analyticPath}/network/spent_time`,
  AVG_TTFB: `${analyticPath}/network/ttfb`,
  AVG_VISIBLE_SLOTS_PER_PAGE: `${analyticPath}/network/visible_slots_per_page`,
  // ANALYTICS KPIS
  KPI_ACTIRISE_REVENUE: `${analyticPath}/monetizations/actirise_revenue`,
  KPI_BOUNCE_RATE_CMP: `${analyticPath}/gdpr/eu/bounce_rate`,
  KPI_BRAND_SAFE_RATE: `${analyticPath}/content/brandsafety/brandsafe_rate`,
  KPI_CLS: `${analyticPath}/technical/cls`,
  KPI_CONSENT_RATE: `${analyticPath}/gdpr/eu/consent_rate`,
  KPI_CRAWLER_RATE: `${analyticPath}/audiences/page_views/crawler_rate`,
  KPI_DIRECT_REVENUE: `${analyticPath}/monetizations/direct_revenue`,
  KPI_FCP: `${analyticPath}/technical/fcp`,
  KPI_FEED_REVENUE: `${analyticPath}/discovery/revenue`,
  KPI_FEED_RPM: `${analyticPath}/discovery/rpm`,
  KPI_FEED_RPM_SESSION: `${analyticPath}/discovery/rpm_session`,
  KPI_FID: `${analyticPath}/technical/fid`,
  KPI_FULL_CONSENT_RATE: `${analyticPath}/gdpr/eu/page_views/full_consent_rate`,
  KPI_GDPR_PAGE_VIEWS_RATE: `${analyticPath}/gdpr/eu/page_views_rate`,
  KPI_INCREMENTAL_TIME_ON_SITE: `${analyticPath}/discovery/incremental_time_on_site`,
  KPI_INP: `${analyticPath}/technical/inp`,
  KPI_LCP: `${analyticPath}/technical/lcp`,
  KPI_PAGE_VIEWS: `${analyticPath}/audiences/page_views`,
  KPI_PAGE_VIEWS_WITHOUT_ADS: `${analyticPath}/audiences/page_views?slots=0`,
  KPI_PAGE_VIEWS_WITHOUT_ADS_RATE: `${analyticPath}/audiences/page_views/without_ads_rate`,
  KPI_PARTNER_REVENUE: `${analyticPath}/monetizations/partner_revenue`,
  KPI_PAGE_VIEWS_PER_SESSION: `${analyticPath}/audiences/page_views_per_session`,
  KPI_RECOVERED_PAGE_VIEWS: `${analyticPath}/discovery/recovered_page_views`,
  KPI_RECOVERED_PAGE_VIEWS_RATE: `${analyticPath}/discovery/recovered_page_views_rate`,
  KPI_RPM_PAGE_VIEWS: `${analyticPath}/monetizations/rpm_page_views`,
  KPI_RPM_SESSIONS: `${analyticPath}/monetizations/rpm_sessions`,
  KPI_SESSIONS: `${analyticPath}/audiences/sessions`,
  KPI_SPENT_TIME: `${analyticPath}/audiences/page_views/spent_time`,
  KPI_TTFB: `${analyticPath}/technical/ttfb`,
  KPI_VIEWABILITY: `${analyticPath}/discovery/viewability`,
  KPI_VISIBLE_SLOTS_PER_PAGE: `${analyticPath}/monetizations/visible_slots_per_page`,
  // ANALYTICS VARIOUS
  BRAND_SAFETY_KEYWORDS: `${analyticPath}/content/brandsafety/keywords`,
  BRAND_SAFETY_NOTBS_URLS: `${analyticPath}/content/brandsafety/notbs_urls`,
  METADATA: `${analyticPath}/content/metadata`
})

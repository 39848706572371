export default Object.freeze({
  ANONYMOUS: 'anonymous',
  AVERAGE: 'average_',
  CHART_THEME: 'actirise',
  CHART_THEME_DARK: 'actirise-dark',
  CURRENCY: 'currency',
  EDITED_KPI: 'editedKpi',
  IDLE: 'idle',
  KPI_1: 'kpi1',
  KPI_2: 'kpi2',
  KPI_TOGGLE_INDEX: 'kpiToggleIndex',
  LOADING: 'loading',
  MAX_DAYS_INTERVAL: 'max-days-interval',
  NON_APPLICABLE: 'N/A',
  NONE: 'none',
  OTHERS: 'others',
  PUBLISHER_INFO_SEEN: 'publisherInfoSeen',
  PUBLISHER_ITEMS: 'publisherItems',
  PUBLISHER_ITEMS_DOMAINS: 'publisherItemsDomains',
  SEPARATOR: '~',
  SDK_PREVIEW: 'hbdbrk-hbskw=1',
  TIMEZONE: 'timezone',
  TIMEZONE_DEFAULT: 'Etc/GMT',
  // Filters - Dimensions
  BROWSER: 'browser',
  COUNTRY: 'country',
  CRAWLER: 'crawler_name',
  CUSTOM_VAR_1: 'var_custom1',
  CUSTOM_VAR_2: 'var_custom2',
  CUSTOM_VAR_3: 'var_custom3',
  CUSTOM_VAR_4: 'var_custom4',
  CUSTOM_VAR_5: 'var_custom5',
  CUSTOM_VAR_ALL: 'var_custom1,var_custom2,var_custom3,var_custom4,var_custom5',
  DEVICE: 'device',
  DOMAIN: 'domain',
  FILTERS: 'filters',
  IS_CUSTOMER: 'is_customer',
  ORIGIN_SOURCE: 'origin_source',
  ORIGIN_SUPPORT: 'origin_support',
  PUBLISHERS: 'domain',
  UTM_ALL: 'utm_campaign,utm_source,utm_medium,utm_content',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  VAR_PAGE_TYPE: 'var_page_type',
  // Date picker
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  REAL_TIME: 'real_time',
  REAL_TIME_INTERVAL: 30000,
  REAL_TIME_OFF: 'off',
  REAL_TIME_ON: 'on',
  // Shortcut date picker
  SHORTCUT_DATE: 'shortcut_date',
  SHORTCUT_DATE_NONE: 'none',
  NOW_30M: 'now-30m',
  TODAY_SO_FAR: 'today-so-far',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last-7-days',
  LAST_WEEK: 'last-week',
  THIS_MONTH: 'this-month',
  LAST_30_DAYS: 'last-30-days',
  LAST_MONTH: 'last-month',
  // Table
  ITEMS_PER_PAGE: 'itemsPerPage',
  ORDER: 'order',
  PAGE: 'page',
  SORT: 'sort',
  // Core Web Vitals
  CLS_INTERVAL: [0.1, 0.25],
  FCP_INTERVAL: [1800, 3000],
  FID_INTERVAL: [100, 300],
  INP_INTERVAL: [200, 500],
  LCP_INTERVAL: [2500, 4000],
  TTFB_INTERVAL: [800, 1800]
})

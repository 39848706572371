import LRU from 'lru-cache'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import groupBy from 'just-group-by'
import unique from 'just-unique'
import clone from 'just-clone'
import dashboard from '@declarations/dashboard'
import statusCode from '@declarations/statusCode'
import config from '@declarations/config'
import endpointsEnums from '@declarations/endpoints'
import { enable as enableURLStorage, disable as disableURLStorage } from '@utils/flatURLStorage'
import prepareParams from '@utils/dashboardParams'
import { timezones } from '@fixtures/timezones'

dayjs.extend(timezone)

// Constants
const [domainParam, pageParam] = ['domain', 'page']
const referenceDate = 'date'
const userAverageEndpoint = 'userAverageEndpoint'

const cacheConfig = {
  max: 1000, // Max number of different URL that can be stored
  ttl: 30 * 60 * 1000 // Number of ms to keep cached data
}

// Cache for service data
const cache = new LRU(cacheConfig)
// Cache for publishers service data
const publisherItemsCache = new LRU({
  max: 1,
  ttl: 3 * 60 * 1000
})
// Cache for metadata service data
const metadataCache = new LRU({
  max: 100,
  ttl: 30 * 60 * 1000
})

const dimensions = [
  dashboard.START_DATE,
  dashboard.END_DATE,
  dashboard.CURRENCY,
  dashboard.TIMEZONE,
  dashboard.PUBLISHERS,
  dashboard.IS_CUSTOMER,
  dashboard.DEVICE,
  dashboard.ORIGIN_SOURCE,
  dashboard.COUNTRY,
  dashboard.CUSTOM_VAR_1,
  dashboard.CUSTOM_VAR_2,
  dashboard.CUSTOM_VAR_3,
  dashboard.CUSTOM_VAR_4,
  dashboard.CUSTOM_VAR_5,
  dashboard.CRAWLER
]

// Create a cache for each dimension
const dimensionCaches = Object.fromEntries(dimensions.map(dimension => [dimension, new LRU(cacheConfig)]))

// Filters will be updated when any of these values change
const updateFiltersOn = [
  dashboard.START_DATE,
  dashboard.END_DATE,
  dashboard.SHORTCUT_DATE,
  dashboard.TIMEZONE,
  dashboard.PUBLISHERS,
  dashboard.IS_CUSTOMER
]

// Minimal filters
const minimalFilters = updateFiltersOn.concat([dashboard.CURRENCY])

// Items will be updated when any of these values change
const updateItemsOn = [
  dashboard.START_DATE,
  dashboard.END_DATE,
  dashboard.SHORTCUT_DATE,
  dashboard.CURRENCY,
  dashboard.TIMEZONE,
  dashboard.PUBLISHERS,
  dashboard.IS_CUSTOMER,
  dashboard.DEVICE,
  dashboard.ORIGIN_SOURCE,
  dashboard.COUNTRY,
  dashboard.CUSTOM_VAR_1,
  dashboard.CUSTOM_VAR_2,
  dashboard.CUSTOM_VAR_3,
  dashboard.CUSTOM_VAR_4,
  dashboard.CUSTOM_VAR_5,
  dashboard.CRAWLER
]

export const state = {
  kpiPerRoute: {},
  [dashboard.PUBLISHER_ITEMS]: null,
  [dashboard.PUBLISHER_ITEMS_DOMAINS]: null,
  [dashboard.PUBLISHER_INFO_SEEN]: false,
  [dashboard.LOADING]: false,
  [dashboard.KPI_1]: null,
  [dashboard.KPI_2]: null,
  [dashboard.KPI_TOGGLE_INDEX]: 0,
  [dashboard.ITEMS_PER_PAGE]: null,
  [dashboard.PAGE]: null,
  [dashboard.ORDER]: null,
  [dashboard.SORT]: null,
  [dashboard.START_DATE]: null,
  [dashboard.END_DATE]: null,
  [dashboard.SHORTCUT_DATE]: dashboard.TODAY_SO_FAR,
  lastDurationMinutes: 30,
  [dashboard.REAL_TIME]: dashboard.REAL_TIME_ON,
  [dashboard.CURRENCY]: 'EUR',
  [dashboard.TIMEZONE]: timezones.includes(dayjs.tz.guess()) ? dayjs.tz.guess() : dashboard.TIMEZONE_DEFAULT,
  [dashboard.PUBLISHERS]: null,
  [dashboard.IS_CUSTOMER]: true,
  [dashboard.DEVICE]: null,
  [dashboard.ORIGIN_SOURCE]: null,
  [dashboard.COUNTRY]: null,
  [dashboard.CUSTOM_VAR_1]: null,
  [dashboard.CUSTOM_VAR_2]: null,
  [dashboard.CUSTOM_VAR_3]: null,
  [dashboard.CUSTOM_VAR_4]: null,
  [dashboard.CUSTOM_VAR_5]: null,
  [dashboard.CRAWLER]: null,
  dimensions: {},
  [dashboard.EDITED_KPI]: null,
  [dashboard.ANONYMOUS]: false,
  [dashboard.IDLE]: false,
  [dashboard.MAX_DAYS_INTERVAL]: 90
}

export const getters = {
  /**
   * Return a serial, composed of dimensions and provided service
   * @returns {(string) => string}
   */
  getSerial: state => service => [
    service,
    ...updateItemsOn.map(key => state[key])
  ].join(dashboard.SEPARATOR),
  /**
   * Return a serial for the provided dimension
   * @returns {(string) => string}
   */
  getDimensionSerial: state => dimension =>
    updateFiltersOn
      .filter(key => key !== dimension)
      .map(key => state[key])
      .join(dashboard.SEPARATOR),
  /**
   * Return the active items (items are cached using serial)
   * This does not update the TTL, so it can be used to check current state of the app
   * @returns {(string) => array}
   */
  getItems: (state, getters) => service => cache.peek(getters.getSerial(service)),
  /**
   * Return number of publishers
   * @param state
   * @returns {number}
   */
  countPublishers: state => state[dashboard.PUBLISHER_ITEMS_DOMAINS]?.length,
  /**
   * Return number of selected publishers
   * @param state
   * @returns {number}
   */
  countSelectedPublishers: state => state[dashboard.PUBLISHERS]?.split(dashboard.SEPARATOR)?.length || 0,
  /**
   * Return number of prospects
   * @param state
   * @returns {number}
   */
  countProspects: state => state[dashboard.PUBLISHER_ITEMS]?.filter(publisher => publisher.isHeader)
    .flatMap(publisher => publisher.publishers.filter(i => !i.isCustomer))?.length,
  /**
   * Return number of days between two dates
   * @param state
   * @returns {number}
   */
  getDiffDays: state => {
    if (!state[dashboard.START_DATE] || !state[dashboard.END_DATE]) return 0

    return dayjs(state[dashboard.END_DATE]).diff(dayjs(state[dashboard.START_DATE]), 'day')
  },
  /**
   * Verify if dates interval is greather than specific value
   * @param state
   * @param getters
   * @returns {boolean}
   */
  greatherMaxDaysInterval: (state, getters) => getters.getDiffDays > state[dashboard.MAX_DAYS_INTERVAL],
  /**
   * Return today so far state
   * @param state
   * @returns {boolean}
   */
  todaySoFar: state => state[dashboard.SHORTCUT_DATE] === dashboard.TODAY_SO_FAR,
  /**
   * Return short interval state (last 30mn)
   * @param state
   * @returns {boolean}
   */
  shortInterval: state => state[dashboard.SHORTCUT_DATE] === dashboard.NOW_30M,
  /**
   * Is real time data
   * @param state
   * @returns {boolean}
   */
  isRealTimeData: state => [dashboard.NOW_30M, dashboard.TODAY_SO_FAR].includes(state[dashboard.SHORTCUT_DATE]),
  /**
   * Is today or yesterday
   * @param state
   * @returns {boolean}
   */
  isTodayOrYesterday: state => {
    const today = dayjs().startOf('day').format('YYYY-MM-DD')
    const yesterday = dayjs().startOf('day').subtract(1, 'day').format('YYYY-MM-DD')
    return [dashboard.NOW_30M, dashboard.TODAY_SO_FAR, dashboard.YESTERDAY].includes(state[dashboard.SHORTCUT_DATE]) ||
      (state[dashboard.START_DATE] === today && state[dashboard.END_DATE] === today) ||
      (state[dashboard.START_DATE] === yesterday && state[dashboard.END_DATE] === yesterday)
  },
  /**
   * Add blur text in anonymous mode state
   * @param state
   * @returns {string|null}
   */
  anonymousModeText: state => (state[dashboard.ANONYMOUS] ? 'text-blur' : null),
  /**
   * Return Filters params for API requests
   * @param state - {boolean} all (keys to extract) | {string|null} customParams (specific parameters)
   * @returns {Object}
   */
  getApiParams: state =>
    (all = true, customParams = null) => prepareParams(state, all ? updateItemsOn : minimalFilters, customParams),
  /**
   * Chart renderer (canvas default or svg)
   * @param state
   * @param getters
   * @returns {Object|null}
   */
  chartRenderer: (state, getters) => (getters.isRealTimeData ? null : { renderer: 'svg' })
}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value

    // Store route-specific KPIs
    if ([dashboard.KPI_1, dashboard.KPI_2].includes(key)) {
      const { name } = this.$router.currentRoute
      if (!state.kpiPerRoute[name]) state.kpiPerRoute[name] = {}
      state.kpiPerRoute[name][key] = value
    }
  },
  /*
    Set all custom vars at once (avoid updating the store multiple times)
  */
  setCustomVars (state, customVars) {
    [
      dashboard.CUSTOM_VAR_1,
      dashboard.CUSTOM_VAR_2,
      dashboard.CUSTOM_VAR_3,
      dashboard.CUSTOM_VAR_4,
      dashboard.CUSTOM_VAR_5
    ].forEach(key => {
      state[key] = customVars[key] || null
    })
  },
  /*
    Reset any KPI value that is not in kpis. Reset value by priority order:
    - Any KPI the user has picked on this page before
    - Any KPI specified in route.meta.defaultKPIs
    - First (and second) KPI in kpis
    If kpis is falsy, remove selected KPI
  */
  enterPage (state, kpis) {
    enableURLStorage()

    // Add none value
    if (kpis) {
      kpis.push({
        key: dashboard.NONE,
        line: true
      })
    }

    const route = this.$router.currentRoute;
    [dashboard.KPI_1, dashboard.KPI_2].forEach((key, index) => {
      if (!kpis) state[key] = null
      else if (!kpis.some(field => field.key === state[key])) {
        const storedKpi = state.kpiPerRoute[route.name]?.[key]
        state[key] = (kpis.some(option => option.key === storedKpi) ? storedKpi : null) // Filter stored kpi if not in options
          || route.meta?.defaultKPIs?.[index]
          || kpis.filter(kpi => kpi.key !== referenceDate)?.[index]?.key
      }
    })
  },
  enterPageExplore (state, kpis) {
    enableURLStorage()

    const route = this.$router.currentRoute
    if (!kpis) state[dashboard.KPI_1] = null
    else if (!kpis.some(field => field.key === state[dashboard.KPI_1])) {
      state[dashboard.KPI_1] = route.meta?.defaultKPIs?.[0] || kpis.filter(kpi => kpi.key !== referenceDate)?.[0]?.key
    }
  },
  /**
   * Clear filters params
   * @param state
   */
  leavePage (state) {
    disableURLStorage()
    state[dashboard.KPI_1] = null
    state[dashboard.KPI_2] = null
  },
  /*
    Clear all caches
  */
  clearCache () {
    cache.clear()
    publisherItemsCache.clear()
    metadataCache.clear()
    Object.values(dimensionCaches)
      .forEach(dimensionCache => dimensionCache.clear())
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */

export const actions = {
  /**
   * Fetch active items from service / take them from cache if available, and return them
   * @param {ActionContext} context
   * @param {Array} fields - used keys for each field are the following:
   *                         - key: id of the field
   *                         - dataKey (default to key): key as returned by API
   *                         - endpoint: API endpoint for main value
   *                         - averageEndpoint: API endpoint for average value
   *                         - bestPerformerEndpoint: API endpoint for best performer
   *                         - limit: overload number of results for the field
   * @param {string} reference - table and chart items will be grouped by reference
   * @param {boolean} [useMetaData=false] - export domain/page data display by referenceField KPI
   * @param {Object} suffixes - keys can be card_items, card_items_average, card_items_user_average, chart_items, table_items, group_by_items
   *                            omitted keys will not be fetched
   *                            value will be merged as query params of each related query
   *                          - referenceField: fetch this field first, then fetch the other fields to match returned items
   * @param {string} serialAppend - append to the cache serials (allow to store query with the same serial in different cache keys)
   * @returns {Object}
   */
  async fetchItems (
    {
      state, commit, getters, dispatch
    },
    {
      fields,
      reference = referenceDate,
      useMetaData = false,
      suffixes = {
        card_items: {},
        card_items_average: {},
        card_items_user_average: {},
        chart_items: {},
        table_items: {},
        group_by_items: null
      },
      serialAppend = ''
    }
  ) {
    const params = prepareParams(state, updateItemsOn)

    // Short interval
    const isShortInterval = getters.shortInterval

    // Fill item empty fields with 0
    const defaultItem = item => ({
      ...Object.fromEntries(fields.map(field => ([field.key, 0]))),
      ...item
    })

    // Object for metadata params {domain: [], page: []}
    const metaDataParams = {}
    let metaData = []

    // Showing spinner
    commit('set', {
      key: dashboard.LOADING,
      value: true
    })

    /**
     * Fetch data for a given endpoint
     * @param {string} endpoint - URL to fetch
     * @param {Object} suffix - Suffix for cache key
     * @param {string} timeline - Timeline param
     * @param {Array} relatedFields - Fields related to the endpoint
     * @param {Object} endpointParams - Parameters for the call
     * @returns {Array}
     */
    const fetchEndpoint = async (
      {
        endpoint, suffix, timeline, relatedFields, endpointParams
      }
    ) => {
      const serial = `${getters.getSerial(endpoint)}_${suffix}${serialAppend}`

      let value

      // Don't use cache if refresh data scope
      const useCache = !getters.isRealTimeData

      // Try to get the data from cache
      if (useCache) value = cache.get(serial)

      // If any related field has limit, use it instead of default parameters
      const limit = relatedFields.some(field => field.limit)
        ? Math.max(...relatedFields.map(field => field.limit))
        : suffixes[suffix].limit || params.limit

      if (!value) {
        try {
          value = (await this.$services.analyticsService.getKPI(
            endpoint,
            {
              ...endpointParams,
              timeline,
              ...{
                ...suffixes[suffix],
                referenceField: undefined,
                reference: undefined
              },
              limit
            },
            !suffixes[suffix]?.limit
          )).data.datas
        } catch (e) {
          value = []

          // Set real time to off for stop API refresh request
          if ([statusCode.INTERNAL_SERVER_ERROR, statusCode.BAD_REQUEST].includes(e?.response?.status)) {
            commit('set', {
              key: dashboard.REAL_TIME,
              value: dashboard.REAL_TIME_OFF
            })
          }
        }

        // Set cache
        if (useCache) cache.set(serial, value)
      }

      return value
    }

    /**
     * Fetch data for each field
     * @param {Object} suffix - Suffix for cache key
     * @param {string} timeline - Timeline param
     * @param {Function} callback - Function called for each field with (key, value)
     * @param {string} endpointKey - Field endpoint to fetch (can be "endpoint", "averageEndpoint" or "userAverageEndpoint")
     */
    const fetchData = async (suffix, timeline, callback, endpointKey = 'endpoint') => {
      const endpointParams = clone(params)

      // If there is a reference field, fetch it first then use the returned values to query other endpoints
      const referenceField = suffixes[suffix]?.referenceField
      let referenceValue
      if (referenceField) {
        const field = fields.find(f => f.key === referenceField)
        referenceValue = await fetchEndpoint({
          endpoint: field.endpoint,
          suffix,
          timeline,
          relatedFields: [field],
          endpointParams
        })

        reference.split(',').forEach(ref => {
          Object.assign(endpointParams, {
            [ref]: unique(referenceValue.map(item => item[ref]))
          })
          // Hydration metadata params
          if (useMetaData && [domainParam, pageParam].includes(ref)) {
            metaDataParams[ref] = unique(referenceValue.map(item => item[ref]))
          }
        })

        // Metadata request
        if (useMetaData) {
          const serial = `${getters.getSerial(endpointsEnums.METADATA)}_${suffix}${serialAppend}`
          metaData = await dispatch(
            'fetchMetadata',
            {
              params: metaDataParams,
              serial
            }
          )
        }
      }

      // Return null for fields that don't have endpoint
      fields.filter(f => !f[endpointKey]).forEach(({ key }) => callback(key, null))

      // Make only one request per endpoint
      await Promise.all(Object.entries(
        groupBy(
          fields.filter(f => f[endpointKey]),
          field => field[endpointKey]
        )
      ).map(async ([endpoint, relatedFields]) => {
        let value

        // If no item is found in the referenceField, skip
        if (referenceField && !referenceValue.length) value = []
        // Reuse fetched value from reference field
        else if (referenceField && relatedFields.some(field => field.key === referenceField)) value = referenceValue
        else {
          // If endpointKey is userAverageEndpoint, we override the domain parameter
          if (endpointKey === userAverageEndpoint && endpointParams[dashboard.DOMAIN]) {
            delete endpointParams[dashboard.DOMAIN]
          }

          value = await fetchEndpoint({
            endpoint,
            suffix,
            timeline,
            relatedFields,
            endpointParams
          })
        }

        relatedFields.forEach(({ key, dataKey }) => callback(key, dataKey || key, value))
      }))
    }

    // KPIs items
    const cardItems = Object.fromEntries(fields.map(({ key }) => [key, {
      value: undefined,
      average: undefined,
      userAverage: undefined,
      loading: true
    }]))
    let chartItems = []
    let tableItems = []
    let groupByItems = []
    const groupByItemsTemp = []

    /**
     * When reference is composed of multiple comma separated fields, add a new concatenated field
     * @param {Object} item
     * @param {string} [scopeReference=reference] - used for specific reference
     */
    const mergeReferences = (item, scopeReference = reference) => {
      if (scopeReference.indexOf(',') !== -1) {
        Object.assign(item, {
          [scopeReference]: scopeReference
            .split(',')
            .map(refProp => item[refProp])
            .filter(ref => ref)
            .join(', ')
        })
      }
    }

    // Display good timeline for chart items request
    let chartItemsTimeline = 'auto'
    if (params[dashboard.START_DATE] && params[dashboard.END_DATE]) {
      const diffDays = dayjs(params[dashboard.END_DATE]).diff(params[dashboard.START_DATE], 'day')
      if (diffDays >= 1 && diffDays < 7) chartItemsTimeline = '1 day'
    }

    await Promise.all([
      // Fetch card items
      suffixes.card_items && fetchData('card_items', undefined, async (key, dataKey, value) => {
        cardItems[key].value = value?.[0]?.[dataKey] || null
        if (typeof cardItems[key].average !== 'undefined') cardItems[key].loading = false
      }),

      // Fetch card items average
      suffixes.card_items_average && fetchData('card_items_average', undefined, async (key, dataKey, value) => {
        cardItems[key].average = value?.[0]?.[dataKey] || null
        if (typeof cardItems[key].value !== 'undefined') cardItems[key].loading = false
      }, 'averageEndpoint'),

      // Fetch card items user average
      suffixes.card_items_user_average && fetchData('card_items_user_average', undefined, async (key, dataKey, value) => {
        cardItems[key].userAverage = value?.[0]?.[dataKey] || null
        if (typeof cardItems[key].value !== 'undefined') cardItems[key].loading = false
      }, userAverageEndpoint),

      // Fetch chart items
      suffixes.chart_items && fetchData('chart_items', chartItemsTimeline, (key, dataKey, value) => {
        const chartItemsReference = suffixes.chart_items?.reference || reference

        value.forEach(item => {
          if (item && key !== dataKey) {
            Object.assign(item, {
              [key]: item[dataKey]
            })
          }

          mergeReferences(item, chartItemsReference)

          const dataPoint = chartItems.find(i => i[chartItemsReference] === item[chartItemsReference])
          if (dataPoint) Object.assign(dataPoint, item)
          else chartItems.push(defaultItem(item))
        })

        // Order by date
        if (chartItemsReference === referenceDate) {
          chartItems = chartItems.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        }

        // Remove last not pertinent value in last interval scope
        if (isShortInterval && chartItems.length && chartItemsReference === referenceDate) {
          chartItems = chartItems.slice(0, -1)
        }
      }),

      // Fetch table items
      suffixes.table_items && fetchData('table_items', isShortInterval ? 'auto' : '1 day', (key, dataKey, value) => {
        value.forEach(item => {
          if (item && key !== dataKey) {
            Object.assign(item, {
              [key]: item[dataKey]
            })
          }

          mergeReferences(item)

          const dataPoint = tableItems.find(i => i[reference] === item[reference])
          if (dataPoint) Object.assign(dataPoint, item)
          else tableItems.push(defaultItem(item))
        })

        // Remove last not pertinent value in short interval scope
        if (isShortInterval && tableItems.length && reference === referenceDate) {
          tableItems = tableItems.slice(0, -1)
        }
      }),

      // Fetch group by items
      suffixes.group_by_items && fetchData('group_by_items', chartItemsTimeline, (key, dataKey, value) => {
        const groupByItemsReference = suffixes.group_by_items?.reference || reference
        const groupByKey = suffixes.group_by_items?.group_by

        value.forEach(item => {
          if (item && key !== dataKey) {
            Object.assign(item, {
              [key]: item[dataKey]
            })
          }

          mergeReferences(item, groupByItemsReference)

          groupByItemsTemp.push(item)
        })

        // Group by items parser
        groupByItems = Object.entries(groupBy(groupByItemsTemp, item => item[groupByItemsReference]))
          .map(([groupByItemsRef, groupByItemsValues]) => {
            const data = Object.entries(groupBy(groupByItemsValues, item => item[groupByKey]))
              .map(([groupByRef, groupByValues]) => {
                let values = Object.assign({}, ...groupByValues)
                values = defaultItem(values)
                // Delete not necessary keys
                delete values[groupByItemsReference]
                delete values[groupByKey]
                return {
                  [groupByRef]: values
                }
              })

            return {
              [groupByItemsReference]: groupByItemsRef,
              ...Object.assign({}, ...data)
            }
          })

        // Order by date
        if (groupByItemsReference === referenceDate) {
          groupByItems = groupByItems.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        }

        // Remove last not pertinent value in short interval scope
        if (isShortInterval && groupByItems.length && groupByItemsReference === referenceDate) {
          groupByItems = groupByItems.slice(0, -1)
        }
      })
    ])

    commit('set', {
      key: dashboard.LOADING,
      value: false
    })

    return {
      cardItems,
      chartItems,
      tableItems,
      groupByItems,
      metaData
    }
  },
  /**
   * Fetch active dimension from service / take them from cache if available, and return them
   * @param {ActionContext} context
   * @param {Object} options - { dimension: String - Dimension to fetch, limit: Number - Limit number of results }
   * @returns {AxiosPromise|Array}
   */
  async fetchDimensions ({ state, getters, commit }, { dimension, limit }) {
    const serial = getters.getDimensionSerial(dimension)

    const params = prepareParams(state, updateFiltersOn, dashboard.FILTERS)

    // Don't use cache if real time scope
    const useCache = !getters.isRealTimeData

    // Try to get the data from cache
    const cached = dimensionCaches[dimension].get(serial)
    if (useCache && cached) return cached

    let value
    try {
      value = (await this.$services.analyticsService.getDimensions(
        dimension,
        {
          ...params,
          limit: limit || 5000
        },
        typeof limit === 'undefined' // Don't follow next if limit is specified
      )).data?.datas // TODO for legacy
    } catch (e) {
      value = []

      // Set real time to off for stop API refresh request
      if ([statusCode.INTERNAL_SERVER_ERROR, statusCode.BAD_REQUEST].includes(e?.response?.status)) {
        commit('set', {
          key: dashboard.REAL_TIME,
          value: dashboard.REAL_TIME_OFF
        })
      }
    }

    // Set cache
    dimensionCaches[dimension].set(serial, value)

    return value
  },
  /**
   * Fetch publishers from service / take them from cache if available, and return them
   * @param {ActionContext} context
   * @param {Object} params
   * @returns {Array}
   */
  async fetchPublishers ({ commit }, params) {
    // Try to get the data from cache
    const cached = publisherItemsCache.get(dashboard.PUBLISHER_ITEMS)
    if (cached) return cached

    const { data } = await this.$services.websitesService.getWebsites(params)
    // TODO for legacy
    const publishersData = config.LEGACY ? data?.datas : data?.['hydra:member']
    // TODO for legacy
    if (!config.LEGACY) {
      const organizations = (await this.$services.organizationsService.getOrganizations(params)).data?.['hydra:member']
      publishersData.forEach(publisher => {
        Object.assign(publisher, { entity: organizations.find(organisation => organisation['@id'] === publisher.organization) })
      })
    }

    // TODO for legacy
    const getId = item => (config.LEGACY ? item.id : item['@id'])

    // Group publishers by organization, and add one entry per organization to select all publishers in this organization
    const publishersItems = Object.values(groupBy(publishersData, publisher => getId(publisher.entity)))
      .sort((a, b) => a[0].entity.name.localeCompare(b[0].entity.name)) // Sort entities by name
      .flatMap(organizationPublishers => [
        {
          id: `${getId(organizationPublishers[0].entity)}_organization`, // Some websites share id with their organization
          isHeader: true,
          domain: organizationPublishers[0].entity.name, // for matching with domain key website
          publishers: organizationPublishers.map(item => ({
            domain: item.domain,
            // TODO for legacy
            isCustomer: config.LEGACY ? item?.current_config?.version > 1 : item?.websiteConfigs.length > 1
          }))
        },
        ...organizationPublishers.sort((a, b) => a.domain.localeCompare(b.domain)) // Sort publishers by domain
      ])

    // Keep data in store
    commit('set', {
      key: dashboard.PUBLISHER_ITEMS,
      value: publishersItems
    })
    // Keep domain data in store
    commit('set', {
      key: dashboard.PUBLISHER_ITEMS_DOMAINS,
      value: publishersData.map(publisher => publisher?.domain)
    })

    // Set cache
    publisherItemsCache.set(dashboard.PUBLISHER_ITEMS, publishersItems)

    return publishersItems
  },
  /**
   * Fetch metadata from service / take them from cache if available, and return them
   * @param {ActionContext} context
   * @param {Object} params
   * @param {string} [serial=''] - key used for cache
   * @returns {Array}
   */
  async fetchMetadata ({ getters, commit }, { params, serial = '' }) {
    // Domain and page params are mandatory
    if (!(domainParam in params)
      || !(pageParam in params)
      || !params[domainParam].length
      || !params[pageParam].length) return []

    // Don't use cache if real time scope
    const useCache = !getters.isRealTimeData

    // Try to get the data from cache
    const cached = metadataCache.get(serial)
    if (useCache && cached) return cached

    let metadataValues

    try {
      metadataValues = (await this.$services.analyticsService.getKPI(endpointsEnums.METADATA, params))
        .data?.datas // TODO for legacy
    } catch (e) {
      metadataValues = []

      if ([statusCode.INTERNAL_SERVER_ERROR, statusCode.BAD_REQUEST].includes(e?.response?.status)) {
        commit('set', {
          key: dashboard.REAL_TIME,
          value: dashboard.REAL_TIME_OFF
        })
      }
    }

    // Set cache
    metadataCache.set(serial, metadataValues)

    return metadataValues
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@declarations/table'
import apiTokenStatus from '@declarations/apiTokenStatus'

/**
 * @typedef {Object} ApiTokens
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member.@type
 * @prop {boolean} hydraMember.name - as hydra:member.name
 * @prop {string} hydraMember.createdAt - as hydra:member.createdAt
 * @prop {string} hydraMember.status - as hydra:member.status
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} ApiToken
 * @prop {string} name
 * @prop {string} createdAt
 * @prop {string} status
 */

/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {ApiToken[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {{order: {name:asc}}} [tableFiltersState.order.name=asc]
 * @prop {{status: string}} [tableColumnFilters.status=active]
 */

/** @type {State} */
export const state = {
  service: {
    name: 'apiTokensService',
    getItemsMethod: 'getApiTokens',
    patchItemMethod: 'updateApiToken'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    status: apiTokenStatus.ACTIVE
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import views from '@declarations/views'
import config from '@declarations/config'
import dashboardKPI from '@declarations/dashboardKPI'
import dashboard from '@declarations/dashboard'
import store from '@store'

const DashboardHomePage = () =>
  import(/* webpackChunkName: "dashboard-homepage" */ '@views/dashboard/DashboardHomePage.vue')
const DashboardOverview = () =>
  import(/* webpackChunkName: "dashboard-overview" */ '@views/dashboard/DashboardOverview.vue')
const DashboardExplore = () =>
  import(/* webpackChunkName: "dashboard-explore" */ '@views/dashboard/DashboardExplore.vue')
const DashboardMonetization = () =>
  import(/* webpackChunkName: "dashboard-monetization" */ '@views/dashboard/DashboardMonetization.vue')
const DashboardMonetizationAdvancedView = () =>
  import(/* webpackChunkName: "dashboard-monetization-advanced-view" */ '@views/dashboard/DashboardMonetizationAdvancedView.vue')
const DashboardGDPR = () =>
  import(/* webpackChunkName: "dashboard-gdpr" */ '@views/dashboard/DashboardGDPR.vue')
const DashboardGDPRAdvancedView = () =>
  import(/* webpackChunkName: "dashboard-gdpr-advanced-view" */ '@views/dashboard/DashboardGDPRAdvancedView.vue')
const DashboardAudience = () =>
  import(/* webpackChunkName: "dashboard-audience" */ '@views/dashboard/DashboardAudience.vue')
const DashboardAudienceAdvancedView = () =>
  import(/* webpackChunkName: "dashboard-audience-advanced-view" */ '@views/dashboard/DashboardAudienceAdvancedView.vue')
const DashboardBrandSafety = () =>
  import(/* webpackChunkName: "dashboard-brand-safety" */ '@views/dashboard/DashboardBrandSafety.vue')
const DashboardDiscovery = () =>
  import(/* webpackChunkName: "dashboard-discovery" */ '@views/dashboard/DashboardDiscovery.vue')
const DashboardCoreWebVitals = () =>
  import(/* webpackChunkName: "dashboard-core-web-vitals" */ '@views/dashboard/DashboardCoreWebVitals.vue')
const DashboardCoreWebVitalsAdvancedView = () =>
  import(/* webpackChunkName: "dashboard-core-web-vitals-advanced-view" */ '@views/dashboard/DashboardCoreWebVitalsAdvancedView.vue')
const DashboardCrawler = () =>
  import(/* webpackChunkName: "dashboard-crawler" */ '@views/dashboard/DashboardCrawler.vue')

const DashboardDemo = () =>
  import(/* webpackChunkName: "dashboard-demo" */ '@views/dashboard/DashboardDemo.vue')

const breadcrumb = label => [
  {
    text: label,
    active: true
  }
]

const advancedViewBreadcrumb = (label, viewsName) => [
  {
    text: label,
    to: { name: viewsName }
  },
  {
    text: () => store.state.dashboard[dashboard.EDITED_KPI],
    active: true
  }
]

const kpiValidator = '[a-zA-Z0-9_]+'

const defaultKPIsExplore = [dashboardKPI.PAGE_VIEWS]
const groupByLimit = 5

const defaultMeta = {
  analytics: true,
  publisherSelect: true,
  datePicker: true,
  cannotChangeLocale: true
}

export default [
  {
    path: `/${views.DASHBOARD}`,
    name: views.DASHBOARD,
    component: DashboardHomePage,
    meta: {
      ...defaultMeta,
      layout: config.LAYOUT_FULL_MENU
    }
  },
  {
    path: `/${views.DASHBOARD}-overview`,
    name: views.DASHBOARD_OVERVIEW,
    component: DashboardOverview,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('overview'),
      defaultKPIs: [dashboardKPI.RPM_PAGE_VIEWS, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-domain`,
    name: views.DASHBOARD_DOMAIN,
    component: DashboardExplore,
    props: {
      reference: dashboard.DOMAIN,
      groupByLimit,
      groupByDetails: true
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('domain'),
      defaultKPIs: defaultKPIsExplore
    }
  },
  {
    path: `/${views.DASHBOARD}-content`,
    name: views.DASHBOARD_CONTENT,
    component: DashboardExplore,
    props: {
      reference: 'domain,page',
      limit: 500
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('content')
    }
  },
  {
    path: `/${views.DASHBOARD}-device`,
    name: views.DASHBOARD_DEVICE,
    component: DashboardExplore,
    props: {
      reference: dashboard.DEVICE,
      groupByLimit,
      groupByDetails: true
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('device'),
      defaultKPIs: defaultKPIsExplore
    }
  },
  {
    path: `/${views.DASHBOARD}-browser`,
    name: views.DASHBOARD_BROWSER,
    component: DashboardExplore,
    props: {
      reference: dashboard.BROWSER,
      groupByDetails: true
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('browser'),
      defaultKPIs: defaultKPIsExplore
    }
  },
  {
    path: `/${views.DASHBOARD}-country`,
    name: views.DASHBOARD_COUNTRY,
    component: DashboardExplore,
    props: {
      reference: dashboard.COUNTRY,
      groupByDetails: true
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('country')
    }
  },
  {
    path: `/${views.DASHBOARD}-page-type`,
    name: views.DASHBOARD_PAGE_TYPE,
    component: DashboardExplore,
    props: {
      reference: dashboard.VAR_PAGE_TYPE,
      groupByLimit,
      groupByDetails: true
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('pageType'),
      defaultKPIs: defaultKPIsExplore
    }
  },
  {
    path: `/${views.DASHBOARD}-source-support`,
    name: views.DASHBOARD_SOURCE_SUPPORT,
    component: DashboardExplore,
    props: {
      reference: dashboard.ORIGIN_SOURCE,
      groupByLimit,
      excludeOnGroupByLimit: [dashboard.ORIGIN_SOURCE],
      groupByDetails: true,
      limit: 100
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('sourceSupport'),
      defaultKPIs: defaultKPIsExplore
    }
  },
  {
    path: `/${views.DASHBOARD}-utm`,
    name: views.DASHBOARD_UTM,
    component: DashboardExplore,
    props: {
      reference: dashboard.UTM_CAMPAIGN,
      groupByDetails: true,
      limit: 500
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('utm')
    }
  },
  {
    path: `/${views.DASHBOARD}-custom-var`,
    name: views.DASHBOARD_CUSTOM_VAR,
    component: DashboardExplore,
    props: {
      reference: dashboard.CUSTOM_VAR_1,
      groupByDetails: true,
      limit: 500
    },
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('customVar')
    }
  },
  {
    path: `/${views.DASHBOARD}-monetization`,
    name: views.DASHBOARD_MONETIZATION,
    component: DashboardMonetization,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('monetization'),
      defaultKPIs: [dashboardKPI.RPM_PAGE_VIEWS, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-monetization/:kpi(${kpiValidator})/:average(${kpiValidator})?`,
    name: views.DASHBOARD_MONETIZATION_ADVANCED_VIEW,
    props: true,
    component: DashboardMonetizationAdvancedView,
    meta: {
      ...defaultMeta,
      appBreadcrumb: advancedViewBreadcrumb('monetization', views.DASHBOARD_MONETIZATION)
    }
  },
  {
    path: `/${views.DASHBOARD}-gdpr`,
    name: views.DASHBOARD_GDPR,
    component: DashboardGDPR,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('gdpr'),
      defaultKPIs: [dashboardKPI.FULL_RATE, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-gdpr/:kpi(${kpiValidator})/:average(${kpiValidator})?`,
    name: views.DASHBOARD_GDPR_ADVANCED_VIEW,
    props: true,
    component: DashboardGDPRAdvancedView,
    meta: {
      ...defaultMeta,
      appBreadcrumb: advancedViewBreadcrumb('gdpr', views.DASHBOARD_GDPR)
    }
  },
  {
    path: `/${views.DASHBOARD}-audience`,
    name: views.DASHBOARD_AUDIENCE,
    component: DashboardAudience,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('audience'),
      defaultKPIs: [dashboardKPI.PAGE_VIEWS_WITHOUT_ADS_RATE, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-audience/:kpi(${kpiValidator})/:average(${kpiValidator})?`,
    name: views.DASHBOARD_AUDIENCE_ADVANCED_VIEW,
    props: true,
    component: DashboardAudienceAdvancedView,
    meta: {
      ...defaultMeta,
      appBreadcrumb: advancedViewBreadcrumb('audience', views.DASHBOARD_AUDIENCE)
    }
  },
  {
    path: `/${views.DASHBOARD}-brand-safety`,
    name: views.DASHBOARD_BRAND_SAFETY,
    component: DashboardBrandSafety,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('brandSafety'),
      defaultKPIs: [dashboardKPI.BRAND_SAFE_RATE, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-discovery`,
    name: views.DASHBOARD_DISCOVERY,
    component: DashboardDiscovery,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('discovery'),
      defaultKPIs: [dashboardKPI.FEED_REVENUE, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-core-web-vitals`,
    name: views.DASHBOARD_CORE_WEB_VITALS,
    component: DashboardCoreWebVitals,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('coreWebVitals'),
      defaultKPIs: [dashboardKPI.TTFB, dashboard.NONE]
    }
  },
  {
    path: `/${views.DASHBOARD}-core-web-vitals/:kpi(${kpiValidator})/:average(${kpiValidator})?`,
    name: views.DASHBOARD_CORE_WEB_VITALS_ADVANCED_VIEW,
    props: true,
    component: DashboardCoreWebVitalsAdvancedView,
    meta: {
      ...defaultMeta,
      appBreadcrumb: advancedViewBreadcrumb('coreWebVitals', views.DASHBOARD_CORE_WEB_VITALS)
    }
  },
  {
    path: `/${views.DASHBOARD}-crawler/:kpi(${kpiValidator})/:average(${kpiValidator})?`,
    name: views.DASHBOARD_CRAWLER,
    props: true,
    component: DashboardCrawler,
    meta: {
      ...defaultMeta,
      appBreadcrumb: breadcrumb('crawler')
    }
  },
  // Just for chart dev
  {
    path: `/${views.DASHBOARD}-demo`,
    name: 'dashboard-demo',
    component: DashboardDemo,
    meta: {
      analytics: true,
      publisherSelect: true
    }
  }
]

export default Object.freeze({
  DEFAULT: 'MinusIcon',
  ACCOUNT: 'AppUserIcon',
  ACCOUNTS: 'UsersIcon',
  ACTIVITY: 'ActivityIcon',
  ADS_TXT: 'FileTextIcon',
  ADVANCED_EDITION: 'SettingsIcon',
  ASSOCIATE: 'UserPlusIcon',
  AT_SIGN: 'AtSignIcon',
  BACK: 'ChevronLeftIcon',
  BIDDERS: 'ThumbsUpIcon',
  BIDDERS_TEMPLATES_MANAGER: 'BookOpenIcon',
  CODE: 'CodeIcon',
  CONFIGS: 'HashIcon',
  CONSUMPTION: 'EyeIcon',
  COPY: 'CopyIcon',
  CREATE: 'PlusSquareIcon',
  DASHBOARD_AUDIENCE: 'AppUsersGroupIcon',
  DASHBOARD_BRAND_SAFETY: 'AppShieldIcon',
  DASHBOARD_CORE_WEB_VITALS: 'AppPulseIcon',
  DASHBOARD_CRAWLER: 'AppRobotIcon',
  DASHBOARD_DISCOVERY: 'AppActiriseIcon',
  DASHBOARD_EXPLORE: 'AppExploreIcon',
  DASHBOARD_GDPR: 'AppGDPRIcon',
  DASHBOARD_MONETIZATION: 'AppMonetIcon',
  DASHBOARD_OVERVIEW: 'AppPieIcon',
  DASHBOARD_REAL_TIME: 'RadioIcon',
  DELETE: 'Trash2Icon',
  DIFF: 'GitPullRequestIcon',
  DOC: 'FileTextIcon',
  DOWNLOAD: 'DownloadIcon',
  DRAG_DROP: 'AppDragDropIcon',
  EDIT: 'EditIcon',
  EVENT: 'ShuffleIcon',
  FEES: 'DollarSignIcon',
  GENERAL: 'FolderIcon',
  HOMEPAGE: 'HomeIcon',
  INFO: 'InfoIcon',
  INJECTION: 'TerminalIcon',
  IN_IMAGE: 'ImageIcon',
  LEGACY_PLATFORM: 'SettingsIcon',
  LINK: 'LinkIcon',
  LOCK: 'LockIcon',
  LOGOUT: 'LogOutIcon',
  MAIL: 'MailIcon',
  MEDIAS: 'ImageIcon',
  MONITORING: 'EyeIcon',
  MOVE: 'MoveIcon',
  NOTE: 'TypeIcon',
  ORGANIZATIONS: 'AppOrganizationIcon',
  PRESIZED_DIVS: 'SlidersIcon',
  PUBLISH: 'AirplayIcon',
  PURGE: 'WindIcon',
  REVOKE: 'XSquareIcon',
  SLOTS: 'LayoutIcon',
  TAGGING: 'SettingsIcon',
  TARGETS: 'CrosshairIcon',
  TEMPLATE: 'GridIcon',
  UNLINK: 'UserMinusIcon',
  UNLOCK: 'UnlockIcon',
  WEBSITES: 'GlobeIcon'
})

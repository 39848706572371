import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import { isObject } from '@utils'
import table from '@declarations/table'

/**
 * @typedef {Object} Accounts
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member.@type
 * @prop {boolean} hydraMember.active - as hydra:member.active
 * @prop {string} hydraMember.createdAt - as hydra:member.createdAt
 * @prop {string} hydraMember.email - as hydra:member.email
 * @prop {string} hydraMember.firstname - as hydra:member.firstname
 * @prop {string} hydraMember.gender - as hydra:member.gender
 * @prop {string} hydraMember.lastLoginAt - as hydra:member.lastLoginAt
 * @prop {string} hydraMember.lastname - as hydra:member.lastname
 * @prop {string} hydraMember.phoneNumber - as hydra:member.phoneNumber
 * @prop {Array<string>} hydraMember.roles - as hydra:member.roles
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/** @typedef {import('/modules/table').TableFiltersState} */
/** @typedef {import('/modules/user').LocalUser} User */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {Accounts[]} [items]
 * @prop {number} totalItems
 * @prop {{order: {firstName:asc}}} [tableFiltersState.order.firstName=asc]
 * @prop {{active: boolean}} [tableColumnFilters.active=true]
 * @prop {User} editedItem
 */

/** @type {State} */
export const state = {
  service: {
    name: 'usersService',
    getItemsMethod: 'getUsers',
    getItemMethod: 'getUser',
    patchItemMethod: 'updateUser'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      firstName: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    active: true
  }
}

export const getters = {
  ...gettersTable,
  /**
   * @param {State} state
   */
  editedAccountFullName (state) {
    if (!isObject(state.editedItem)) {
      return ''
    }
    return `${state.editedItem?.firstName} ${state.editedItem?.lastName}`
  }
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

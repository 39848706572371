// Import core styles
import '@core/scss/core.scss'
// Import assets styles
import '@/assets/scss/style.scss'
// Import toastification styles
import '@core/scss/vue/libs/toastification.scss'

import Vue from 'vue'

// Plugins registration
import '@plugins'

// Directives registration
import '@directives'

// Matomo tracker
import setupMatomo from '@matomo'

// Global const for acl usage
import actions from '@declarations/actions'
import subjects from '@declarations/subjects'

// Global Components registration
import './global-components'

import { loadLanguageAsync, i18n } from '@i18n'
import config from '@declarations/config'
import createApp from './app'

// Vue configuration
const debug = config.ENVIRONMENT !== 'production'
Vue.config.productionTip = !debug // To prevent the production tip on Vue startup
Vue.config.silent = !debug // Suppress all Vue logs and warnings
Vue.config.devtools = debug // Authorizes inspection vue-devtools
Vue.config.performance = debug // Enable component init, compile, render and patch performance

// For acl
Vue.prototype.$actions = actions
Vue.prototype.$subjects = subjects

const start = async () => {
  const { app, store, router } = createApp()

  app.use(i18n)

  try {
    await Promise.all([
      loadLanguageAsync(i18n.locale),
      store.dispatch('auth/authenticate')
    ])
  } catch (error) {
    // eslint-disable-next-line no-console
    if (config.ENVIRONMENT === 'development') console.info(error?.message)
  }

  if (config.ENVIRONMENT === 'production' && !config.PRE_PROD) {
    setupMatomo({
      store,
      router
    })
  }

  app.mount('#app')
}

start().then()

// In relation with dashboardsKPI locale
export default Object.freeze({
  // Mixed API KPIs
  PAGE_VIEWS: 'page_views',
  PAGE_VIEWS_RATE: 'page_views_rate',
  REVENUE: 'revenue',
  RPM: 'rpm',
  // Others KPIs
  ACTIRISE_REVENUE: 'actirise_revenue',
  ADVERTISING_PRESSURE: 'advertising_pressure',
  BRAND_SAFE_RATE: 'brand_safe_rate',
  BOUNCE_RATE_CMP: 'bounce_rate',
  CLS: 'cls_75_percentile',
  CRAWLER_RATE: 'crawler_rate',
  DIRECT_REVENUE: 'direct_revenue',
  FCP: 'fcp_75_percentile',
  FEED_REVENUE: 'feed_revenue',
  FEED_RPM: 'feed_rpm',
  FEED_RPM_SESSION: 'feed_rpm_session',
  FID: 'fid_75_percentile',
  FULL_CONSENT_RATE: 'full_consent_rate',
  FULL_RATE: 'full_rate',
  GDPR_PAGE_VIEWS_RATE: 'page_views_rate',
  INCREMENTAL_SPENT_TIME: 'incremental_spent_time',
  INP: 'inp_75_percentile',
  LCP: 'lcp_75_percentile',
  NO_BRAND_SAFE_PAGE_VIEWS: 'no_brand_safe_page_views',
  NO_BRAND_SAFE_RATE: 'no_brand_safe_rate',
  NO_CONSENT_RATE: 'none_rate',
  PAGE_VIEWS_PER_SESSION: 'page_views_per_session',
  PAGE_VIEWS_WITHOUT_ADS: 'page_views_without_ads',
  PAGE_VIEWS_WITHOUT_ADS_RATE: 'page_views_without_ads_rate',
  PARTIAL_CONSENT_RATE: 'partial_rate',
  PARTNER_REVENUE: 'partner_revenue',
  RECOVERED_PAGE_VIEWS: 'recovered_page_views',
  RECOVERED_PAGE_VIEWS_RATE: 'recovered_page_views_rate',
  RPM_PAGE_VIEWS: 'rpm_page_views',
  RPM_SESSIONS: 'rpm_sessions',
  SESSIONS: 'sessions',
  SPENT_TIME: 'spent_time',
  TTFB: 'ttfb_75_percentile',
  UNKNOWN_BRAND_SAFE_PAGE_VIEWS: 'unknown_brand_safe_page_views',
  UNKNOWN_BRAND_SAFE_RATE: 'unknown_brand_safe_rate',
  UNKNOWN_CONSENT_RATE: 'unknown_rate',
  VIEWABILITY: 'viewability',
  VISIBLE_SLOTS_PER_PAGE: 'visible_slots_per_page'
})

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { createVuexHelpers } from 'vue2-helpers'
import { storage as flatURLStorage } from '@utils/flatURLStorage'
import dashboardEnums from '@declarations/dashboard'

// Modules
import app from './modules/app'
import appConfig from './modules/appConfig'
import verticalMenu from './modules/verticalMenu'
import user from './modules/user'
import auth from './modules/auth'
import genders from './modules/genders'
import countries from './modules/countries'
import currencies from './modules/currencies'
import organizationTypes from './modules/organizationTypes'
import accounts from './modules/accounts'
import organizations from './modules/organizations'
import organizationsAccounts from './modules/organizationsAccounts'
import organizationsWebsites from './modules/organizationsWebsites'
import websites from './modules/websites'
import websiteConfig from './modules/websiteConfig'
import websiteConfigsList from './modules/websiteConfigsList'
import pendingTagActivation from './modules/pendingTagActivation'
import biddersWebsiteConfig from './modules/biddersWebsiteConfig'
import apiTokens from './modules/apiTokens'
import unpublishedDraft from './modules/unpublishedDraft'
import bidders from './modules/bidders'
import adsTxt from './modules/adsTxt'
import dashboard from './modules/dashboard'

Vue.use(Vuex)

const storageName = process.env.VUE_APP_STORAGE_NAME
const storage = localStorage

const vuexPersist = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage,
  reducer: state => ({
    user: {
      rememberMyEmail: state.user?.rememberMyEmail,
      rememberedEmail: state.user?.rememberedEmail
    },
    dashboard: {
      [dashboardEnums.TIMEZONE]: state.dashboard[dashboardEnums.TIMEZONE]
    }
  })
})

const vuexPersistSession = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage: sessionStorage,
  reducer: state => ({
    dashboard: {
      [dashboardEnums.ANONYMOUS]: state.dashboard[dashboardEnums.ANONYMOUS],
      [dashboardEnums.IDLE]: state.dashboard[dashboardEnums.IDLE],
      [dashboardEnums.IS_CUSTOMER]: state.dashboard[dashboardEnums.IS_CUSTOMER]
    },
    websites: {
      websitesScraping: state.websites.websitesScraping
    }
  })
})

const vuexPersistURL = new VuexPersistence({
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage: flatURLStorage('dashboard'),
  reducer: state => ({
    dashboard: {
      [dashboardEnums.KPI_1]: state.dashboard[dashboardEnums.KPI_1],
      [dashboardEnums.KPI_2]: state.dashboard[dashboardEnums.KPI_2],
      [dashboardEnums.ITEMS_PER_PAGE]: state.dashboard[dashboardEnums.ITEMS_PER_PAGE],
      [dashboardEnums.PAGE]: state.dashboard[dashboardEnums.PAGE],
      [dashboardEnums.ORDER]: state.dashboard[dashboardEnums.ORDER],
      [dashboardEnums.SORT]: state.dashboard[dashboardEnums.SORT],
      [dashboardEnums.START_DATE]: state.dashboard[dashboardEnums.START_DATE],
      [dashboardEnums.END_DATE]: state.dashboard[dashboardEnums.END_DATE],
      [dashboardEnums.SHORTCUT_DATE]: state.dashboard[dashboardEnums.SHORTCUT_DATE],
      [dashboardEnums.REAL_TIME]: state.dashboard[dashboardEnums.REAL_TIME],
      [dashboardEnums.CURRENCY]: state.dashboard[dashboardEnums.CURRENCY],
      [dashboardEnums.TIMEZONE]: state.dashboard[dashboardEnums.TIMEZONE],
      [dashboardEnums.PUBLISHERS]: state.dashboard[dashboardEnums.PUBLISHERS],
      [dashboardEnums.DEVICE]: state.dashboard[dashboardEnums.DEVICE],
      [dashboardEnums.ORIGIN_SOURCE]: state.dashboard[dashboardEnums.ORIGIN_SOURCE],
      [dashboardEnums.COUNTRY]: state.dashboard[dashboardEnums.COUNTRY],
      [dashboardEnums.CUSTOM_VAR_1]: state.dashboard[dashboardEnums.CUSTOM_VAR_1],
      [dashboardEnums.CUSTOM_VAR_2]: state.dashboard[dashboardEnums.CUSTOM_VAR_2],
      [dashboardEnums.CUSTOM_VAR_3]: state.dashboard[dashboardEnums.CUSTOM_VAR_3],
      [dashboardEnums.CUSTOM_VAR_4]: state.dashboard[dashboardEnums.CUSTOM_VAR_4],
      [dashboardEnums.CUSTOM_VAR_5]: state.dashboard[dashboardEnums.CUSTOM_VAR_5],
      [dashboardEnums.CRAWLER]: state.dashboard[dashboardEnums.CRAWLER]
    }
  })
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    auth,
    genders,
    countries,
    currencies,
    organizationTypes,
    accounts,
    organizations,
    organizationsAccounts,
    organizationsWebsites,
    websites,
    websiteConfig,
    websiteConfigsList,
    pendingTagActivation,
    biddersWebsiteConfig,
    apiTokens,
    unpublishedDraft,
    bidders,
    adsTxt,
    dashboard
  },
  strict: process.env.VUE_APP_ENVIRONMENT !== 'production',
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    setStoreInitialization (state) {
      // Check if item exist
      if (storage.getItem(storageName)) {
        // Replace the state object with the stored item
        this.replaceState(Object.assign(state, JSON.parse(storage.getItem(storageName))))
      }
    }
  },
  plugins: [vuexPersist.plugin, vuexPersistSession.plugin, vuexPersistURL.plugin]
})

// Vuex helpers
export const {
  useState, useGetters, useMutations, useActions
} = createVuexHelpers()

import subjects from '@declarations/subjects'
import actions from '@declarations/actions'

const pType = 'p'
const pTypeP2 = 'p2'
const role = 'customer'

export default [
  // Management
  {
    pType,
    v0: role,
    v1: subjects.MANAGEMENT,
    v2: actions.LIST,
    v3: null
  },
  // Organization
  {
    pType,
    v0: role,
    v1: subjects.ORGANIZATION,
    v2: actions.LIST,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.ORGANIZATION,
    v2: actions.GET,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.ORGANIZATION,
    v2: actions.CREATE,
    v3: null
  },
  // User
  {
    pType,
    v0: role,
    v1: subjects.USER,
    v2: actions.LIST,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.USER,
    v2: actions.GET,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.USER,
    v2: actions.UPDATE,
    v3: null
  },
  // Website
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE,
    v2: subjects.FIELD_ALLOWED_USERS,
    v3: actions.LIST
  },
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE,
    v2: actions.GET,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE,
    v2: actions.CREATE,
    v3: null
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE,
    v2: subjects.FIELD_ALLOWED_USERS,
    v3: actions.UPDATE
  },
  // Api token
  {
    pType,
    v0: role,
    v1: subjects.API_TOKEN,
    v2: actions.LIST,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.API_TOKEN,
    v2: actions.GET,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.API_TOKEN,
    v2: actions.CREATE,
    v3: null
  },
  {
    pType,
    v0: role,
    v1: subjects.API_TOKEN,
    v2: actions.DELETE,
    v3: null
  },
  // Website config
  {
    pType,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: actions.LIST,
    v3: null
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_PRESIZED_DIVS,
    v3: actions.GET
  },
  {
    pType: pTypeP2,
    v0: role,
    v1: subjects.WEBSITE_CONFIG,
    v2: subjects.FIELD_ADS_TXT,
    v3: actions.GET
  }
]

import bidders from '@fixtures/bidders-website-config'
import biddersWebsiteConfigTemplates from '@fixtures/bidders-website-config-templates.json'

const state = {
  items: bidders,
  biddersTemplates: biddersWebsiteConfigTemplates.data
}

export const getters = {
  /**
   * Return the bidder associated with a bidder
   * @param {State} state
   * @returns {Function}
   */
  getBidder: state => bidder => state.items.find(({ id }) => id === bidder.id),
  /**
   * Return the bidder template associated with a bidder
   * @returns {Function}
   */
  getBidderTemplate: () => bidder => biddersWebsiteConfigTemplates.data.find(({ id }) => id === bidder.target)
}

export const mutations = {}

export const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

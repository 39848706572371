export default Object.freeze({
  ADMIN_ROLE: 'admin',
  APP_NAME: process.env.VUE_APP_NAME,
  BOUNCE_HEADER: process.env.VUE_APP_BOUNCE_HEADER,
  CUSTOMER_ROLE: 'customer',
  DARK_LAYOUT: 'dark',
  DEFAULT_LANGUAGE: process.env.VUE_APP_DEFAULT_LANGUAGE,
  DOC: process.env.VUE_APP_DOCUMENTATION_URL,
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT,
  JWT_EXPIRED_MESSAGE: 'Expired JWT Token',
  JWT_STORAGE_KEY: 'actirise-jwt',
  LAYOUT_FULL: 'full',
  LAYOUT_FULL_MENU: 'full-menu',
  LAYOUT_HORIZONTAL: 'horizontal',
  LIGHT_LAYOUT: 'light',
  LOCALES: process.env.VUE_APP_LOCALES.split('|'),
  PRE_PROD: process.env.VUE_APP_PRE_PROD,
  SKIN_STORAGE_KEY: 'vuexy-skin',
  STORAGE_I18N: 'i18n',
  STORAGE_NAME: process.env.VUE_APP_STORAGE_NAME,
  SUPPORT_EMAIL: 'support@actirise.com',
  TOKEN_TYPE: 'Bearer',
  // TODO for legacy
  LEGACY: process.env.VUE_APP_LEGACY === 'true',
  CROSS_JWT: 'cross-jwt',
  BO_LEGACY: process.env.VUE_APP_PLATFORM_URL
})

import {
  stateAPI, mutationsAPI, actionsAPI, gettersAPI
} from '@store/modules/api'
import endpoints from '@declarations/endpoints'
import config from '@declarations/config'
import genders from '@fixtures/legacy/genders.json'

export const state = {
  endpoint: endpoints.GENDERS,
  ...stateAPI
}
export const mutations = {
  ...mutationsAPI
}
export const getters = {
  ...gettersAPI
}
export const actions = {
  ...actionsAPI,
  // TODO for legacy
  fetchItems: config.LEGACY ? async function fetchItems ({ dispatch }) {
    const items = genders

    const fetchedItemIRIs = dispatch('setItemsByIRIs', items)
    return {
      response: null,
      fetchedItemIRIs
    }
  } : actionsAPI.fetchItems
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

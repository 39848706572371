import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@declarations/table'
import adsTxt from '@declarations/adsTxt'

/**
 * @typedef {Object} AdsTxts
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member.@type
 * @prop {boolean} hydraMember.additionalNeeded - as hydra:member.additionalNeeded
 * @prop {string} hydraMember.adstxt - as hydra:member.adstxt
 * @prop {string} hydraMember.bidder - as hydra:member.bidder
 * @prop {boolean} hydraMember.byDefault - as hydra:member.byDefault
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} AdsTxt
 * @prop {boolean} additionalNeeded
 * @prop {string} adstxt
 * @prop {string} bidder
 * @prop {boolean} byDefault
 */

/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {AdsTxt[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {boolean} [tableColumnFilters.active=true]
 * @prop {string} bidderName
 */

export const state = {
  service: {
    name: 'adsTxtService',
    getItemsMethod: 'getAdsTxts',
    getItemMethod: 'getAdsTxt',
    patchItemMethod: 'updateAdsTxt'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      [adsTxt.BIDDER_NAME]: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    [adsTxt.BIDDER_ACTIVE]: true
  },
  bidderName: null,
  bidderItems: null
}

export const getters = {
  ...gettersTable,
  /**
   * @param {State} state
   */
  bidderName (state) {
    return state.bidderName
  }
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param {State} state
   * @param {string} bidderName
   */
  setBidderName (state, bidderName) {
    state.bidderName = bidderName
  },
  /**
   * @param {State} state
   * @param {Array} bidderItems
   */
  setBidderItems (state, bidderItems) {
    state.bidderItems = bidderItems
  }
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

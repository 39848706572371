import views from '@declarations/views'
import config from '@declarations/config'
import actionsEnums from '@declarations/actions'
import dashboard from '@declarations/dashboard'
import subjects from '@declarations/subjects'
import { AbilityBuilder, Ability } from '@casl/ability'
import Vue from 'vue'
import { isObject } from '@utils'
import clone from 'just-clone'

/**
 * @typedef {Object} Credentials - Credentials submitted by the login form
 * @prop {string} email - User email
 * @prop {string} password - User password
 */

/** @typedef {import('./user').User} User */

export const state = {}

export const getters = {
  isAuthenticated (state, getters, rootState) {
    return !!rootState.user.userData
  }
}

export const mutations = {}

/**
 * Extract user login infos without rememberMyEmail property
 * @param {Credentials} [credentials]
 */
const getLogin = credentials => {
  if (!credentials) return undefined
  const { rememberMyEmail, ...login } = credentials
  return login
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */

export const actions = {
  /**
   * Call the {@link authenticate} function
   * Call the authentication endpoint if the use credentials are provided,
   * otherwise it retrieve the stored jwt and use it for subsequent requests.
   * It then set the fetched user and email preference and redirect to the home page
   * If the authentication process fail, it call the logout action.
   * @param {ActionContext} context - Action context
   * @param {Credentials} [credentials]
   * @return {Promise<User>} Current user
   */
  async authenticate ({
    commit, dispatch, rootState, rootGetters
  }, credentials) {
    try {
      const login = getLogin(credentials)
      const currentUser = await this.$auth.authenticate(login)

      // Clear dashboard cache
      commit('dashboard/clearCache', null, { root: true })

      // Set user data
      commit('user/setUser', currentUser, { root: true })
      commit('user/setRememberMyEmail', credentials, { root: true })

      // TODO for legacy
      if (!config.LEGACY) {
        // Set user groups data
        const { '@id': userIRI } = await currentUser
        const { data: { 'hydra:member': userGroups } } = await this.$services.userGroupsService.getUserGroups({
          user: userIRI,
          pagination: false
        })
        commit('user/setUserGroups', userGroups, { root: true })

        // Fetch ACL values
        const { data: { 'hydra:member': policies } } = await this.$services.casbinPoliciesService.getPolicies({
          pagination: false
        })
        // Set ACL permissions
        commit('user/setPermissions', {
          userRole: rootGetters['user/userRole'],
          permissions: policies
        }, { root: true })
      }

      // TODO for legacy
      if (config.LEGACY) {
        const { type, currency } = await currentUser
        // Set ACL permissions
        commit('user/setPermissions', {
          userRole: type?.code,
          permissions: []
        }, { root: true })
        // Set currency preference
        // eslint-disable-next-line sonarjs/no-duplicate-string
        commit('dashboard/set', {
          key: dashboard.CURRENCY,
          value: currency
        }, { root: true })
      }

      // ACL permissions
      const userPermissions = clone(rootState.user.permissions)
      /**
       * @casl lib https://www.npmjs.com/package/@casl/ability
       * @returns {Ability}
       */
      const defineAbilitiesFor = () => {
        const { can, rules } = new AbilityBuilder(Ability)

        Object.keys(userPermissions).map(action => userPermissions[action].map(subject => {
          // No fields in subject
          if (!isObject(subject)) return can(action, subject)

          // Fields in subject
          return Object.keys(subject).map(item => can(action, item, subject[item]))
        }))

        return new Ability(rules)
      }
      const ability = defineAbilitiesFor()
      Vue.prototype.$can = (action, subject, field) => (action && subject ? ability.can(action, subject, field) : true)

      // Disable active debug mode if unauthorized ability
      if (rootState.app.debugMode && !ability.can(actionsEnums.GET, subjects.DEBUG_MODE)) {
        commit('app/setDebugMode', false, { root: true })
        commit('appConfig/UPDATE_SKIN', config.DARK_LAYOUT, { root: true })
      }

      return currentUser
    } catch (error) {
      await dispatch('clearUser')
      return Promise.reject(error)
    }
  },
  /**
   * Call the auth {@link logout} function
   * then remove the current userData by calling the setUser mutation
   * @param {ActionContext} context - Action context
   */
  clearUser ({ commit }) {
    this.$auth.logout()
    commit('user/setUser', undefined, { root: true })
    // Reinit publishers filter values
    commit('dashboard/set', {
      key: dashboard.IS_CUSTOMER,
      value: true
    }, { root: true })
    commit('dashboard/set', {
      key: dashboard.PUBLISHERS,
      value: null
    }, { root: true })
    commit('dashboard/set', {
      key: dashboard.PUBLISHER_ITEMS,
      value: null
    }, { root: true })
    commit('dashboard/set', {
      key: dashboard.PUBLISHER_ITEMS_DOMAINS,
      value: null
    }, { root: true })
  },
  /**
   * User logout
   * Call the clearUser action then redirect to the login route
   * if it's not the current one
   * @param {ActionContext} context - Action context
   * @param {boolean} [expiredJWTToken=false]
   */
  logout ({ dispatch }, expiredJWTToken = false) {
    dispatch('clearUser')
    if (this.$router.currentRoute.name === views.LOGIN) return undefined
    return this.$router
      .push({
        name: views.LOGIN,
        query: expiredJWTToken === true ? { jwt: 'expired' } : null
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import datetimeFormats from '@i18n/i18n-datetime-formats'
import dashboard from '@declarations/dashboard'
import icons from '@declarations/icons'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Utils for datepicker filter
 * @param {Object} [locale={}]
 * @param {number} [lastDurationMinutes=0]
 * @param {string} [timezoneParam=Etc/GMT]
 */
export default ({ locale = {}, lastDurationMinutes = 0, timezoneParam = dashboard.TIMEZONE_DEFAULT }) => {
  const today = dayjs().startOf('day')
  const yesterday = dayjs().startOf('day').subtract(1, 'day')
  const startLast7Days = dayjs().startOf('day').subtract(7, 'day')
  const endLastDay = dayjs().startOf('day').subtract(1, 'day')
  const startLastWeek = () => dayjs().subtract(1, 'week').startOf('week')
    .add(datetimeFormats[locale.value].firstDayOfWeek, 'day')
  const endLastWeek = () => dayjs().subtract(1, 'week').endOf('week')
    .add(datetimeFormats[locale.value].firstDayOfWeek, 'day')
  const startOfMonth = dayjs().startOf('month')
  const startLast30Days = dayjs().startOf('day').subtract(30, 'day')
  const startLastMonth = dayjs().subtract(1, 'month').startOf('month')
  const endLastMonth = dayjs().subtract(1, 'month').endOf('month')
  const startLastYear = dayjs().subtract(1, 'year').startOf('day')
  const now = dayjs().tz(timezoneParam)

  const shortcuts = [
    {
      key: dashboard.NOW_30M,
      text: 'date.lastDurationMinutes',
      textParams: { duration: lastDurationMinutes },
      icon: icons.DASHBOARD_REAL_TIME,
      value: () => [
        today.$d,
        today.$d
      ]
    },
    {
      key: dashboard.TODAY_SO_FAR,
      text: 'date.todaySoFar',
      icon: icons.DASHBOARD_REAL_TIME,
      value: () => [
        today.$d,
        today.$d
      ]
    },
    {
      key: dashboard.YESTERDAY,
      text: 'date.yesterday',
      value: () => [
        yesterday.$d,
        yesterday.$d
      ]
    },
    {
      key: dashboard.LAST_7_DAYS,
      text: 'date.last7Days',
      value: () => [
        startLast7Days.$d,
        endLastDay.$d
      ]
    },
    {
      key: dashboard.LAST_WEEK,
      text: 'date.lastWeek',
      value: () => [
        startLastWeek().$d,
        endLastWeek().$d
      ]
    },
    {
      key: dashboard.THIS_MONTH,
      text: 'date.thisMonth',
      value: () => [
        startOfMonth.$d,
        today.$d
      ]
    },
    {
      key: dashboard.LAST_30_DAYS,
      text: 'date.last30Days',
      value: () => [
        startLast30Days.$d,
        endLastDay.$d
      ]
    },
    {
      key: dashboard.LAST_MONTH,
      text: 'date.lastMonth',
      value: () => [
        startLastMonth.$d,
        endLastMonth.$d
      ]
    }
  ]

  return computed(() => ({
    maxDate: today.$d,
    shortcuts,
    defaultRange: {
      value: () => [
        today.$d,
        today.$d
      ]
    },
    today: today.format('YYYY-MM-DD'),
    yesterday: yesterday.format('YYYY-MM-DD'),
    startLast7Days,
    endLastDay,
    startLastWeek: startLastWeek(),
    endLastWeek: endLastWeek(),
    startOfMonth,
    startLast30Days,
    startLastMonth,
    endLastMonth,
    startLastYear,
    now
  }))
}

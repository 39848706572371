import axios from '@axios'
import { serialize } from 'object-to-formdata'
import qs from 'qs'
import endpoints from '@declarations/endpoints'
import config from '@declarations/config'
import matchingApiAppKeys from '@declarations/matchingApiAppKeys'
import dashboard from '@declarations/dashboard'

const currencyEndpoints = [
  endpoints.KPI_ACTIRISE_REVENUE,
  endpoints.KPI_DIRECT_REVENUE,
  endpoints.KPI_FEED_REVENUE,
  endpoints.KPI_FEED_RPM,
  endpoints.KPI_FEED_RPM_SESSION,
  endpoints.KPI_PARTNER_REVENUE,
  endpoints.KPI_RPM_PAGE_VIEWS,
  endpoints.KPI_RPM_SESSIONS
]

/**
 * Change API key to APP key and return data
 * @param {string} endpoint
 * @param {Array} data
 * @returns {Array}
 */
const updateApiKeyData = (endpoint, data) => {
  const matching = matchingApiAppKeys.find(item => item?.endpoint === endpoint)
  // No matching
  if (!matching) return data

  // Matching
  return data.map(({ [matching.apiKey]: oldKey, ...rest }) => ({
    [matching.appKey]: oldKey,
    ...rest
  }))
}

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

/**
 * Fetch analytics KPI values
 * @param {string} endpoint
 * @param {Object} [params={}]
 * @param {boolean} [followNext=true] - if true, will call api as async any times as needed to get all data
 * @returns {Promise<{data: {datas: *[]}}>}
 */
const fetchEndpoint = async (endpoint, params = {}, followNext = true) => {
  // Remove currency param for not allowed endpoints
  if (!currencyEndpoints.includes(endpoint)) {
    Object.assign(params, { [dashboard.CURRENCY]: undefined })
  }
  let next = true
  let resultsAll = 0
  let unit = {}
  let body = serialize(params)
  const datas = []

  while (next) {
    // eslint-disable-next-line no-await-in-loop
    const { data } = config.LEGACY ? await axios.post(endpoint, body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }) : { data: { datas: [] } } // TODO for legacy
    datas.push(...updateApiKeyData(endpoint, data.datas))

    next = followNext ? data.next : false
    if (next) {
      body = serialize(qs.parse(new URL(data.next).search, {
        ignoreQueryPrefix: true
      }))
    }

    resultsAll = data?.resultsAll
    unit = data?.unit
  }

  return {
    data: { datas },
    resultsAll,
    unit
  }
}

export default {
  getKPI: fetchEndpoint,
  /**
   * Fetch dimensions values
   * @param {string} dimension
   * @param {Object} [params]
   * @param {boolean} [followNext=true] - if true, will call api as async any times as needed to get all data
   * @returns {Promise<{data: {datas: *[]}}>}
   */
  getDimensions (dimension, params = {}, followNext = true) {
    return fetchEndpoint(endpoints.ANALYTICS_DIMENSIONS.replace('{{dimension}}', dimension), params, followNext)
  }
}

import _objectSpread from "/Users/germainpavot/Projects/abba-backoffice/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import * as featherIcons from 'vue-feather-icons';
import * as appIcons from '@assets/icons';
export default {
  name: 'FeatherIcon',
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object]
    },
    size: {
      type: String,
      default: '14'
    },
    badge: {
      type: [String, Object, Number],
      default: null
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary'
    }
  },
  render: function render(h, _ref) {
    var props = _ref.props,
        data = _ref.data;

    // Use feather icon lib and app icons
    var icons = _objectSpread(_objectSpread({}, featherIcons), appIcons); // Create Icon


    var svg = h(icons[props.icon], _objectSpread({
      props: {
        size: props.size
      }
    }, data)); // If no badge is provided => Render just SVG

    if (!props.badge) return svg; // Badge

    var badgeEl = h('span', {
      staticClass: 'badge badge-up badge-pill',
      class: props.badgeClasses
    }, [props.badge]); // Return span with children

    return h('span', {
      staticClass: 'feather-icon position-relative'
    }, [svg, badgeEl]);
  }
};
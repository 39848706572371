import { set, unref } from '@vue/composition-api'
import config from '@declarations/config'
import endpoints from '@declarations/endpoints'

// TODO for legacy
const id = config.LEGACY ? 'id' : '@id'

/** @typedef {string} IRI */

/** @typedef {Object.<string, any>} Item */

/**
 * @typedef {Object} State
 * @prop {Object.<string, Item>} itemsByIRIs
 * @prop {Boolean} loading
 */

/** @type {State} */
export const stateAPI = {
  itemsByIRIs: {},
  loading: false
}

/**
 * @callback FetchedItems
 * @param {Array.<IRI>} itemIRIs
 * @returns {Array.<Item>}
 */

export const gettersAPI = {
  /**
   * @param {State} state
   * @returns {Array.<Item>}
   */
  items: state => Object.values(state.itemsByIRIs),
  /**
   * @param {State} state
   * @returns {Array.<IRI>}
   */
  itemIRIs: state => Object.keys(state.itemsByIRIs),
  /**
   * @param {State} state
   * @returns {FetchedItems}
   */
  fetchedItems: state => itemIRIs => {
    const rawItemIRIs = unref(itemIRIs)
    if (!rawItemIRIs?.value?.length) return []
    return rawItemIRIs?.value.reduce((items, itemId) => {
      const item = state.itemsByIRIs[itemId]
      if (item) items.push(item)
      return items
    }, [])
  }
}

export const mutationsAPI = {
  /**
   * @param {State} state
   * @param {Item} item
   */
  setItemById (state, item) {
    if (!item) return
    set(state.itemsByIRIs, item[id], item)
  },
  /**
   * @param {State} state
   * @param {Boolean} loading
   */
  setLoading (state, loading) {
    state.loading = loading
  }
}

/** @typedef {import("vuex").ActionContext<State>} ActionContext - Action context */

export const actionsAPI = {
  /**
   * Fetch items from the API then store them in the state
   * @param {ActionContext} context - Action context
   * @param {Object.<string, any>} params - API request params
   */
  async fetchItems ({ state, dispatch }, params) {
    const rawParams = unref(params)
    const response = await this.$axios.get(state.endpoint, { params: rawParams })
    // TODO for legacy
    let items = config.LEGACY ? response?.data?.datas : response?.data?.['hydra:member']
    // TODO FOR legacy
    if (config.LEGACY && state.endpoint === endpoints.CURRENCIES) {
      // Add id key
      items = items.map(item => ({
        ...item,
        id: item.code
      }))
    }
    const fetchedItemIRIs = dispatch('setItemsByIRIs', items)
    return {
      response,
      fetchedItemIRIs
    }
  },
  /**
   * Iterate on provided items, store each one of them in the state
   * and return an array of their IRIs
   * @param {ActionContext} context
   * @param {Array.<Item>} items
   */
  setItemsByIRIs ({ commit }, items) {
    if (!items?.length) return []
    return items.map(item => {
      commit('setItemById', item)
      return item[id]
    })
  }
}

export default Object.freeze({
  // RESOURCES
  ACCOUNT: 'account',
  ADMINISTRATION: 'administration',
  API_TOKEN: 'api_token',
  COUNTRY: 'country',
  DEBUG_MODE: 'debug_mode',
  DEFAULT_ADSTXT: 'default_adstxt',
  DEFAULT_BIDDERS: 'default_bidders',
  GENDER: 'gender',
  MANAGEMENT: 'management',
  ORGANIZATION: 'organization',
  ORGANIZATION_EXCLUSION: 'organization_exclusion',
  ORGANIZATION_INVITATION: 'organization_invitation',
  ORGANIZATION_TYPE: 'organization_type',
  USER: 'user',
  USER_GROUP: 'user_group',
  VALIDATION_EMAIL: 'validation_email',
  WEBSITE: 'website',
  WEBSITE_ADSTXT_MONITORING: 'website_adstxt_monitoring',
  WEBSITE_BIDDERS: 'website_bidders',
  WEBSITE_CONFIG: 'website_config',
  // FIELDS
  FIELD_ACTIVE: 'active',
  FIELD_ADS_TXT: 'adstxt',
  FIELD_ALLOWED_USERS: 'allowed_users',
  FIELD_BIDDERS: 'bidders',
  FIELD_DIFF: 'diff',
  FIELD_FEES: 'fees',
  FIELD_NOTE: 'note',
  FIELD_PLAINPASSWORD: 'plainPassword',
  FIELD_PRESIZED_DIVS: 'presized_divs',
  FIELD_PUBLISH: 'publish',
  FIELD_TAGGING: 'tagging',
  FIELD_UNLOCK: 'unlock',
  FIELD_VALIDATED: 'validated'
})
